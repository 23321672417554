import { defineStore } from 'pinia';
import { Cache } from '~/shared/helpers/cache';
import { SegmentCategoryType } from '~/packages/employees/inventory/employees-segmentation.inventory';
import { FilterOptionType } from '@/packages/filter/types/filter-option.type';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { DatePickerOptionType } from '~/shared/ui/form/date-picker/types/date-picker-option.type';

export const useEmployeeSegmentationStore = defineStore('segmentAnalytics', () => {
  // DATES
  const datepicker = ref({
    selectedOption: undefined as undefined | DatePickerOptionType,
  });

  const dates = computed(() => {
    return datepicker.value?.selectedOption?.dates;
  });
  const dtoDates = computed(() => {
    return {
      starts: dates.value?.from || '',
      ends: dates.value?.to || '',
    };
  });
  const datesDisplay = computed(() => {
    return datepicker.value?.selectedOption?.title;
  });

  function setSelectedDatepickerOption(item: DatePickerOptionType) {
    datepicker.value.selectedOption = item;
  }

  // GROUPING
  const grouping = ref('');
  function setGrouping(groping: string) {
    grouping.value = groping;
  }

  // CATEGORIES
  const categoriesCache = ref(new Cache<Record<string, SegmentCategoryType>>());

  const cacheKeyForCategories = computed(() => {
    return Cache.generateKey({
      d: dates.value,
      g: grouping.value,
      f: selectedFilters.value,
    });
  });
  const categories = computed<Record<string, SegmentCategoryType> | undefined>(() => {
    return categoriesCache.value.getItem(cacheKeyForCategories.value);
  });

  function cacheCategories(payload: {
    key: string;
    categories: Record<string, SegmentCategoryType>;
  }) {
    categoriesCache.value.setItem(payload.key, payload.categories);
  }

  // AVAILABLE FILTERS
  const availableFiltersCache = ref(new Cache<Record<string, FilterOptionType>>());

  const cacheKeyForAvailableFilters = computed(() => {
    return Cache.generateKey({ d: dates.value });
  });
  const availableFilters = computed<Record<string, FilterOptionType> | undefined>(() => {
    return availableFiltersCache.value.getItem(cacheKeyForAvailableFilters.value);
  });

  function cacheAvailableFilters(payload: {
    key: string;
    filters: Record<string, FilterOptionType>;
  }) {
    availableFiltersCache.value.setItem(payload.key, payload.filters);
  }

  // SELECTED FILTERS
  const selectedFiltersCache = ref(new Cache<FilterCriteria>());

  const cacheKeyForSelectedFilters = computed(() => {
    return Cache.generateKey({ g: grouping.value });
  });
  const selectedFilters = computed<FilterCriteria | undefined>(() => {
    return selectedFiltersCache.value.getItem(cacheKeyForSelectedFilters.value);
  });

  function cacheSelectedFilters(payload: { key: string; filters: FilterCriteria }) {
    selectedFiltersCache.value.setItem(payload.key, payload.filters);
  }

  return {
    datepicker,
    dates,
    dtoDates,
    datesDisplay,
    setSelectedDatepickerOption,

    grouping,
    setGrouping,

    categoriesCache,
    cacheKeyForCategories,
    categories,
    cacheCategories,

    availableFiltersCache,
    cacheKeyForAvailableFilters,
    availableFilters,
    cacheAvailableFilters,

    selectedFiltersCache,
    cacheKeyForSelectedFilters,
    selectedFilters,
    cacheSelectedFilters,
  };
});
