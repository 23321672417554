import { FilterOptionType } from '@/packages/filter/types/filter-option.type';
import { FilterFakeService } from '@/packages/fake-data/filter/filter.fake-service';
import { SegmentCategoryType } from '~/packages/employees/inventory/employees-segmentation.inventory';
import { SegmentCategoryItemType } from '~/packages/employees/inventory/employees-segmentation.inventory';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { FilterKeyEnum } from '@/packages/filter/enums/filter-key.enum';

export class SegmentAnalyticsFakeService {
  public static getFilters(): FilterOptionType[] {
    return FilterFakeService.getFilters();
  }

  public static getCategories(targeting: FilterCriteria): Record<string, SegmentCategoryType> {
    const filters = FilterFakeService.getFilters();
    const acc: Record<string, SegmentCategoryType> = {};
    const allUsers: Record<string, number> = {};
    const map = filters.reduce(
      (map, filter) => ({ ...map, [filter.uid]: filter }),
      {} as Record<string, FilterOptionType>
    );

    const visibleFilters = [
      FilterKeyEnum.COUNTRY,
      FilterKeyEnum.CITY,
      FilterKeyEnum.GENERATION,
      FilterKeyEnum.DEI,
      FilterKeyEnum.GENDER,
      FilterKeyEnum.TEAM_MANAGER,
      FilterKeyEnum.TENURE,
      FilterKeyEnum.POSITION,
      FilterKeyEnum.PROMOTION,
      FilterKeyEnum.PAY_INCREASE,
      FilterKeyEnum.PERFORMANCE,
    ].reduce((arr, uid) => {
      if (uid in map && !Object.keys(targeting).includes(uid)) arr.push(map[uid]);

      return arr;
    }, [] as FilterOptionType[]);

    visibleFilters.forEach(filter =>
      filter.items.forEach(item => {
        allUsers[filter.uid] = (allUsers[filter.uid] || 0) + (item.worksNow || 0);
      })
    );

    visibleFilters.forEach(filter => {
      const items: SegmentCategoryItemType[] = [];

      filter.items.forEach(item => {
        items.push({
          name: item.name,
          count: item.worksNow || 0,
        });
      });

      acc[filter.uid] = {
        uid: filter.uid,
        name: filter.name,
        items,
      };
    });

    return acc;
  }
}
