<template>
  <div class="bg-grey-8" :class="classStyle"></div>
</template>

<script lang="ts" setup>
defineComponent({
  name: 'DividerLine',
});

const props = defineProps({
  vertical: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
});

const baseVertical = 'w-1px shrink-0';
const boldVertical = 'w-[2px] shrink-0';

const baseHorizontal = 'h-1px w-full';
const boldHorizontal = 'h-[2px] w-full';

const classStyle = computed(() => {
  if (props.vertical) {
    return props.bold ? boldVertical : baseVertical;
  }

  return props.bold ? boldHorizontal : baseHorizontal;
});
</script>
