export enum ProductivityHeatmapPermissionEnum {
  PRODUCTIVITY_HEATMAP_VIEW = 'PRODUCTIVITY_HEATMAP_VIEW',
  PRODUCTIVITY_HEATMAP_VIEW_USERS = 'PRODUCTIVITY_HEATMAP_VIEW_USERS',
  PRODUCTIVITY_HEATMAP_VIEW_TREND = 'PRODUCTIVITY_HEATMAP_VIEW_TREND',
  PRODUCTIVITY_HEATMAP_SHARE_EMAIL = 'PRODUCTIVITY_HEATMAP_SHARE_EMAIL',
  PRODUCTIVITY_HEATMAP_DOWNLOAD_PDF = 'PRODUCTIVITY_HEATMAP_DOWNLOAD_PDF',
}

export enum ProductivityTrendPermissionEnum {
  PRODUCTIVITY_TREND_VIEW = 'PRODUCTIVITY_TREND_VIEW',
  PRODUCTIVITY_TREND_SHARE_EMAIL = 'PRODUCTIVITY_TREND_SHARE_EMAIL',
  PRODUCTIVITY_TREND_DOWNLOAD_PDF = 'PRODUCTIVITY_TREND_DOWNLOAD_PDF',
}

export default {
  ...ProductivityHeatmapPermissionEnum,
  ...ProductivityTrendPermissionEnum,
};
