import { useApi } from '~/shared/api/api.composable';

export class AppService {
  protected get api() {
    return useApi();
  }

  protected static get api() {
    return useApi();
  }
}
