import { createVuetify } from 'vuetify';
import {
  VTextField,
  VTextarea,
  VSelect,
  VMain,
  VApp,
  VMenu,
  VTooltip,
  VCombobox,
  VForm,
  VChip,
  VSnackbar,
} from 'vuetify/components';
import * as directives from 'vuetify/directives';

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    components: {
      VTextField,
      VTextarea,
      VSelect,
      VMain,
      VApp,
      VMenu,
      VTooltip,
      VCombobox,
      VForm,
      VChip,
      VSnackbar,
    },
    directives,
  });

  nuxtApp.vueApp.use(vuetify);
});
