import { useIntegrationStore } from './integration.store';
import { AppFacade } from '~/app/app.facade';
import { IntegrationEnum } from '@/packages/integration/enums/integration.enum';
import { IntegrationDataTypeEnum } from '@/packages/integration/enums/integration-data-type.enum';
import getIntegrationConfig from '@/packages/integration/helpers/get-integration-config';
import {
  IntegrationsAvailableDatesType,
  IntegrationsStatusType,
} from '@/packages/integration/types/integration.types';
import { IntegrationService } from '@/packages/integration/integration.service';

export class IntegrationFacade extends AppFacade {
  service = new IntegrationService();

  public fetchAndStoreStatus() {
    return this.service.fetchStatus().then(status => this.storeStatus(status));
  }

  public fetchAndStoreAvailableDates() {
    return this.service.fetchAvailableDates().then(dates => this.storeAvailableDates(dates));
  }

  private get store() {
    return useIntegrationStore();
  }

  public storeStatus(status: IntegrationsStatusType) {
    return this.store.setStatus(status);
  }

  public getStatus(): IntegrationsStatusType {
    return this.store.status;
  }

  public isEnabled(platform: IntegrationEnum): boolean {
    const status = this.getStatus();

    if (platform in status) {
      return status[platform]?.isEnabled || false;
    }

    const data = Object.values(status).find(data => {
      if (!data?.products) return false;
      return Object.keys(data?.products || {}).includes(platform);
    });

    return data?.products?.[platform]?.isEnabled || false;
  }

  public get noAvailableDates(): boolean {
    return this.store.noAvailableDates;
  }

  public get availableDates(): IntegrationsAvailableDatesType | null {
    return this.store.availableDates;
  }

  public storeAvailableDates(dates: IntegrationsAvailableDatesType) {
    this.store.setAvailableDates(dates);
  }

  public getIntegrationConfig = getIntegrationConfig;

  public get isStatusFetched(): boolean {
    return this.store.isStatusFetched;
  }

  public get isAvailableDatesFetched(): boolean {
    return this.store.isAvailableDatesFetched;
  }

  public get enabledIntegrations(): IntegrationEnum[] {
    return this.store.enabledIntegrations;
  }

  public get enabledIntegrationDataTypes(): IntegrationDataTypeEnum[] {
    return this.store.enabledIntegrationDataTypes;
  }

  public getIcon(uid: IntegrationEnum) {
    return this.getIntegrationConfig(uid).icon;
  }

  public getName(uid: IntegrationEnum) {
    return this.getIntegrationConfig(uid).name;
  }

  public getDataType(uid: IntegrationEnum) {
    return this.getIntegrationConfig(uid).dataType;
  }
}
