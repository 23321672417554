export class Cache<T> {
  private cache: Record<string, T> = {};

  public setItem(key: string, item: T) {
    this.cache[key] = item;
  }

  public getItem(key: string): T | undefined {
    return this.cache[key];
  }

  public hasItem(key: string): boolean {
    return key in this.cache;
  }

  public static generateKey(value: any, prefix?: string, push?: (el: any) => void): string {
    const arr: string[] = [];
    push = push || ((el: any) => arr.push(el));
    prefix = prefix || '';

    if (Array.isArray(value)) {
      value.forEach(itemValue => {
        const localPrefix = prefix + '[]';
        Cache.generateKey(itemValue, localPrefix, push);
      });
    } else if (value && typeof value === 'object') {
      Object.entries(value).forEach(([itemKey, itemValue]) => {
        const localPrefix = prefix + '[' + itemKey + ']';
        Cache.generateKey(itemValue, localPrefix, push);
      });
    } else {
      push(prefix + ':' + value);
    }

    arr.sort((a, b) => (a > b ? 1 : -1));

    return JSON.stringify(arr);
  }
}
