import dayjs from 'dayjs';
// TODO: Review necessary of ugly ".js extension" for production build
import quarterOfYear from 'dayjs/plugin/quarterOfYear.js';
import minMax from 'dayjs/plugin/minMax.js';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
import utc from 'dayjs/plugin/utc.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import isToday from 'dayjs/plugin/isToday.js';
import isTomorrow from 'dayjs/plugin/isTomorrow.js';
import isYesterday from 'dayjs/plugin/isYesterday.js';
import duration from 'dayjs/plugin/duration.js';
import weekOfYear from 'dayjs/plugin/weekOfYear.js';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import updateLocale from 'dayjs/plugin/updateLocale.js';
import isBetweenPlugin from 'dayjs/plugin/isBetween.js';

dayjs.extend(quarterOfYear);
dayjs.extend(minMax);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(duration);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(updateLocale);
dayjs.extend(isBetweenPlugin);

dayjs.updateLocale('en', { weekStart: 1 });

export default dayjs;

// export const $dayjs = dayjs;
