import { AppService } from '~/app/app.service';
import { AlertType } from '@/packages/attrition/types/alert.type';
import { AlertStatusEnum } from '@/packages/attrition/enums/alert-status.enum';
import { TaskStatusEnum } from '@/packages/attrition/enums/task-status.enum';
import { TaskService } from '@/packages/attrition/services/task.service';

export class AlertStatusService extends AppService {
  public static getStatus(alert: AlertType): AlertStatusEnum {
    const taskStatuses = this.getTaskStatuses(alert);

    if (taskStatuses.includes(TaskStatusEnum.DISMISSED)) {
      return AlertStatusEnum.DISMISSED;
    }

    if (this.getResultTaskStatus(alert) === TaskStatusEnum.ACHIEVED) {
      return AlertStatusEnum.DONE;
    }

    if (taskStatuses.includes(TaskStatusEnum.TODO)) {
      return AlertStatusEnum.TODO;
    }

    if (taskStatuses.includes(TaskStatusEnum.IN_PROGRESS)) {
      return AlertStatusEnum.IN_PROGRESS;
    }

    if (taskStatuses.includes(TaskStatusEnum.SOON)) {
      return AlertStatusEnum.WAITING;
    }

    return AlertStatusEnum.DISMISSED;
  }

  public static getTaskStatuses(alert: AlertType): TaskStatusEnum[] {
    return [
      AlertStatusService.getPerformanceSurveyTaskStatus(alert),
      AlertStatusService.getInitialSurveyTaskStatus(alert),
      AlertStatusService.getSuggestionsTaskStatus(alert),
      AlertStatusService.getFinalSurveyTaskStatus(alert),
      AlertStatusService.getResultTaskStatus(alert),
    ];
  }

  public static getPerformanceSurveyTaskStatus(alert: AlertType): TaskStatusEnum {
    return TaskService.getStatusOfSurveyManagers(alert as AlertType);
  }

  public static getInitialSurveyTaskStatus(alert: AlertType): TaskStatusEnum {
    return TaskService.getStatusOfSurveyEmployeesInitial(alert as AlertType);
  }

  public static getFinalSurveyTaskStatus(alert: AlertType): TaskStatusEnum {
    return TaskService.getStatusOfSurveyEmployeesFinal(alert as AlertType);
  }

  public static getSuggestionsTaskStatus(alert: AlertType): TaskStatusEnum {
    return TaskService.getStatusOfSuggestionManagers(alert as AlertType);
  }

  public static getResultTaskStatus(alert: AlertType): TaskStatusEnum {
    return TaskService.getStatusOfResults(alert as AlertType);
  }
}
