import { defineStore } from 'pinia';
import { GoalDescription } from '../inventory/goal.inventory';

export const useGoalConfigStore = defineStore('goal-config', () => {
  const isConfigFetched = ref(false);
  const availableGoals = ref<GoalDescription[]>([]);

  return {
    isConfigFetched,
    availableGoals,
  };
});
