import { AppService } from '~/app/app.service';
import { Faker } from '@/packages/fake-data/faker';
import { ConclusionFakeData } from '~/packages/attrition/fake-data/conclusion.fake-data';
import { ConclusionType } from '@/packages/attrition/types/conclusion.type';
import { DatesRangeDto, TargetingDto } from '~/shared/api/types/request/common.types';
import { RetainedTopPerformersDailyType } from '@/packages/attrition/types/retained-top-performers-daily.type';
import { ResultsSavingType } from '@/packages/attrition/types/results-saving.type';
import { ResultsComparisonType } from '@/packages/attrition/types/results-comparison.type';

type Dto = DatesRangeDto & TargetingDto;

export class ConclusionService extends AppService {
  public fetchAlertConclusion(alertId: string, isDemo = false): Promise<ConclusionType> {
    if (Faker.isEnabled() || isDemo) {
      return ConclusionFakeData.getConclusion();
    }

    return this.api.core
      .get(`/attrition/alert/${alertId}/result`)
      .then((response: any) => response.data.payload.result);
  }

  public fetchSaving(dto: Dto): Promise<ResultsSavingType> {
    if (Faker.isEnabled()) {
      return ConclusionFakeData.getMoney();
    }

    return this.api.core.post(`/attrition/results/money`, dto).then((response: any) => {
      const payload = response.data.payload;
      payload.topPerformersRetained = Object.values(
        payload.graphPoints as Record<string, RetainedTopPerformersDailyType>
      ).reduce((count: number, point) => count + point.retained, 0);

      return payload;
    });
  }

  public fetchComparison(dto: { starts: string; ends: string }): Promise<ResultsComparisonType> {
    if (Faker.isEnabled()) {
      return ConclusionFakeData.getComparison();
    }

    return this.api.core
      .get(`/attrition/results/comparison`, { params: dto })
      .then((response: any) => response.data.payload);
  }
}
