import { AppService } from '~/app/app.service';
import { Faker } from '~/packages/fake-data/faker';
import { GoalDescription } from '../inventory/goal.inventory';
import { ResponsePayload } from '~/shared/api/types/response/payload';

export type AvailableGoalsResponse = {
  groups: GoalDescription[];
};

export class GoalConfigAPI extends AppService {
  static async fetchAvailableGoals() {
    if (Faker.isEnabled()) {
      return this.api.bff
        .get<ResponsePayload<AvailableGoalsResponse>>('/demo/goal/list')
        .then(response => response.data.payload);
    }

    return this.api.core
      .get<ResponsePayload<AvailableGoalsResponse>>(`/analytics/metrics/groups`)
      .then(response => response.data.payload);
  }
}
