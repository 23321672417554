import { OrganisationLogoItemType } from '@/packages/user/types/organisation.types';

export function getOrganisationLogoSrc(
  item: OrganisationLogoItemType | null | undefined
): string | null {
  if (!item) return null;

  if (typeof item === 'string') return item;

  return item?.svg || item?.png || null;
}
