import { AxiosError } from 'axios';

export function getApiErrorMessage(error: AxiosError): string {
  const status = error.response?.status || 0;

  switch (status) {
    case 400:
    case 401:
    case 403:
    case 404:
      return (error.response?.data as any)?.message;

    case 500:
      return 'Something went wrong on the server';

    default:
      return error.message;
  }
}
