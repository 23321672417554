import { UserFacade } from './user.facade';

export default defineNuxtPlugin(_nuxtApp => {
  const facade = new UserFacade();

  return {
    provide: {
      user: facade,
    },
  };
});
