import dayjs from '~/shared/utils/dayjs';
// TODO: Review and del
// import utcPlugin from 'dayjs/plugin/utc';
import { SurveyTaskType } from '@/packages/attrition/types/survey-task.type';
import { TeamMemberSurveyType } from '@/packages/attrition/types/team-member-survey.type';

// dayjs.extend(utcPlugin);

function isAnswerDeadlineExpired(survey: SurveyTaskType | TeamMemberSurveyType) {
  return dayjs.utc().isAfter(dayjs.utc(survey?.answerDeadline));
}

export default isAnswerDeadlineExpired;
