export default {
  prepareSize(size: any) {
    if (!size || !['string', 'number'].includes(typeof size)) {
      return;
    }

    let str = size + '';
    str = str.trim();

    if (str.match(/^-?[0-9]+$/g)) {
      return size + 'px';
    }

    return size;
  },
};
