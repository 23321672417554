import { MattermostFacade } from './mattermost.facade';

export default defineNuxtPlugin(() => {
  const facade = new MattermostFacade();

  return {
    provide: {
      mattermost: facade,
    },
  };
});
