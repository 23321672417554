import { AppService } from '~/app/app.service';
import dayjs from '~/shared/utils/dayjs';
// TODO: Review and del
// import utcPlugin from 'dayjs/plugin/utc';

import { AlertType } from '@/packages/attrition/types/alert.type';
import { TaskStatusEnum } from '@/packages/attrition/enums/task-status.enum';
import { SuggestionStatusEnum } from '@/packages/attrition/enums/suggestion-status.enum';
import { SurveyStatusEnum } from '@/packages/attrition/enums/survey-status.enum';

import isAnswerDeadlineExpired from '@/packages/attrition/helpers/is-answer-deadline-expired';
import isTaskDismissedByAdmin from '@/packages/attrition/helpers/is-task-dismissed-by-admin';
import isTaskDismissedByEmployees from '@/packages/attrition/helpers/is-task-dismissed-by-employees';

// dayjs.extend(utcPlugin);

export class TaskService extends AppService {
  public static getStatusOfSurveyManagers(alert: AlertType): TaskStatusEnum {
    const task = alert.performanceSurvey;

    if (isTaskDismissedByAdmin(task) || isTaskDismissedByEmployees(task)) {
      return TaskStatusEnum.DISMISSED;
    }

    if (task.status === SurveyStatusEnum.NOT_SENT) {
      return TaskStatusEnum.TODO;
    }

    return isAnswerDeadlineExpired(task) || task.completed === task.totalAvailable
      ? TaskStatusEnum.DONE
      : TaskStatusEnum.IN_PROGRESS;
  }

  public static getStatusOfSurveyEmployeesInitial(alert: AlertType): TaskStatusEnum {
    const task = alert.initialSurvey;

    if (isTaskDismissedByAdmin(task) || isTaskDismissedByEmployees(task)) {
      return TaskStatusEnum.DISMISSED;
    }

    if (task.status === SurveyStatusEnum.NOT_SENT) {
      return TaskStatusEnum.TODO;
    }

    return isAnswerDeadlineExpired(task) || task.completed === task.totalAvailable
      ? TaskStatusEnum.DONE
      : TaskStatusEnum.IN_PROGRESS;
  }

  public static getStatusOfSuggestionManagers(alert: AlertType): TaskStatusEnum {
    const now = dayjs.utc();
    const task = alert.suggestionsForManagers;

    if (isTaskDismissedByAdmin(task)) {
      return TaskStatusEnum.DISMISSED;
    }

    if (task?.status === SuggestionStatusEnum.SENT) {
      return TaskStatusEnum.DONE;
    }

    const isPrevTasksNotDone =
      this.getStatusOfSurveyManagers(alert) !== TaskStatusEnum.DONE ||
      this.getStatusOfSurveyEmployeesInitial(alert) !== TaskStatusEnum.DONE;

    return now.isBefore(task?.availableAt) || isPrevTasksNotDone
      ? TaskStatusEnum.NEXT_STEP
      : TaskStatusEnum.TODO;
  }

  public static getStatusOfSurveyEmployeesFinal(alert: AlertType): TaskStatusEnum {
    const now = dayjs.utc();
    const task = alert.finalSurvey;
    const initialTaskStatus = this.getStatusOfSurveyEmployeesInitial(alert);

    if (isTaskDismissedByAdmin(task) || isTaskDismissedByEmployees(task)) {
      return TaskStatusEnum.DISMISSED;
    }

    if (now.isBefore(alert.finalSurvey.availableAt)) {
      const arePreviousStepsDone =
        alert.suggestionsForManagers?.status === SuggestionStatusEnum.SENT;

      return arePreviousStepsDone ? TaskStatusEnum.SOON : TaskStatusEnum.NEXT_STEP;
    }

    if (initialTaskStatus !== TaskStatusEnum.DONE) {
      return TaskStatusEnum.NEXT_STEP;
    }

    if (task.status === SurveyStatusEnum.NOT_SENT) {
      return TaskStatusEnum.TODO;
    }

    return isAnswerDeadlineExpired(task) || task.completed === task.totalAvailable
      ? TaskStatusEnum.DONE
      : TaskStatusEnum.IN_PROGRESS;
  }

  public static getStatusOfResults(alert: AlertType): TaskStatusEnum {
    const taskStatuses = [
      TaskService.getStatusOfSurveyEmployeesInitial(alert),
      TaskService.getStatusOfSurveyManagers(alert),
      TaskService.getStatusOfSuggestionManagers(alert),
      TaskService.getStatusOfSurveyEmployeesFinal(alert),
    ];

    if (taskStatuses.every(status => status === TaskStatusEnum.DONE))
      return TaskStatusEnum.ACHIEVED;

    if (taskStatuses.includes(TaskStatusEnum.DISMISSED)) return TaskStatusEnum.DISMISSED;

    return TaskStatusEnum.NEXT_STEP;
  }
}
