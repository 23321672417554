<template>
  <div class="base-container transition-all">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContainerBase',
});
</script>

<style lang="scss">
.base-container {
  max-width: 940px;
  padding: 0 32px;
  margin: 0 auto;

  @media (max-width: theme('screens.md')) {
    padding: 0 20px;
  }
}
</style>
