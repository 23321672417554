import { useExpansionPanelStore } from './expansion-panel.store';
import { AppFacade } from '~/app/app.facade';
import { ExpansionPanelConfig } from '~/shared/ui/expansion-panel/expansion-panel.inventory';

export class ExpansionPanelFacade extends AppFacade {
  addConfiguration(options: ExpansionPanelConfig) {
    this.store.addConfiguration(options);
  }

  deleteConfiguration(id: string) {
    this.store.deleteConfiguration(id);
  }

  getConfiguration(id: string) {
    return this.store.configurations[id] || null;
  }

  private get store() {
    return useExpansionPanelStore();
  }
}
