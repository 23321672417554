import { defineStore } from 'pinia';
import { Cache } from '~/shared/helpers/cache';
import { DriversType } from '@/packages/attrition/types/drivers.type';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { DriversDetailsType } from '@/packages/attrition/types/drivers-details.type';
import { DatePickerOptionType } from '~/shared/ui/form/date-picker/types/date-picker-option.type';
import { useDatesStore } from '~/shared/stores/dates.store';

export const useAttritionDriversStore = defineStore('attritionDrivers', () => {
  const datesStore = useDatesStore();
  const datePickerOption = computed(() => datesStore.selectedOption);
  const dates = computed(() => datesStore.selectedDates);
  const datesDisplay = computed(() => datesStore.selectedOption?.title || '');
  function setDatePickerOption(option: DatePickerOptionType) {
    datesStore.setSelectedOption(option);
  }

  const filters = ref<FilterCriteria>({});
  const hasSelectedAnyFilter = computed(() => Object.values(filters.value).some(val => !!val));
  function setFilters(f: FilterCriteria) {
    filters.value = f;
  }

  const driversCache = ref(new Cache<DriversType>());
  const activeDriversCacheKey = computed(() => Cache.generateKey({ d: dates.value }));
  const activeDrivers = computed(() => driversCache.value.getItem(activeDriversCacheKey.value));
  const hasCachedActiveDrivers = computed(() =>
    driversCache.value.hasItem(activeDriversCacheKey.value)
  );
  function cacheDrivers(payload: { key: string; data: DriversType }) {
    driversCache.value.setItem(payload.key, payload.data);
  }

  const detailsCache = ref(new Cache<DriversDetailsType>());
  const activeDetailsCacheKey = computed(() =>
    Cache.generateKey({ d: dates.value, g: filters.value })
  );
  const activeDetails = computed(() => detailsCache.value.getItem(activeDetailsCacheKey.value));
  const hasCachedActiveDetails = computed(() =>
    detailsCache.value.hasItem(activeDetailsCacheKey.value)
  );
  function cacheDetails(payload: { key: string; data: DriversDetailsType }) {
    detailsCache.value.setItem(payload.key, payload.data);
  }

  return {
    datePickerOption,
    dates,
    datesDisplay,
    setDatePickerOption,

    filters,
    hasSelectedAnyFilter,
    setFilters,

    driversCache,
    activeDriversCacheKey,
    activeDrivers,
    hasCachedActiveDrivers,
    cacheDrivers,

    detailsCache,
    activeDetailsCacheKey,
    activeDetails,
    hasCachedActiveDetails,
    cacheDetails,
  };
});
