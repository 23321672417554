import { Faker } from '@/packages/fake-data/faker';
import { IMeResponseSuccess } from '@/server/api/demo/me.post';
import { ILoginResponseSuccess } from '@/server/api/demo/login.post';
import { AppService } from '~/app/app.service';
import { OrganisationType } from '@/packages/user/types/organisation.types';
import { CredentialsType, SessionUserType, SetPasswordResponseType } from '@/packages/user/types/auth.types';
import { UserSessionFakeService } from '@/packages/fake-data/session/user-session.fake-service';
import { LoginTypeEnum } from '@/packages/user/enums/auth.enums';

export class UserSessionService extends AppService {
  login(credentials: CredentialsType): Promise<SessionUserType> {
    if (Faker.isEnabled()) {
      return this.api.bff
        .post('/demo/login', {
          password: credentials.password,
          email: credentials.email,
        })
        .then(response => {
          const data: ILoginResponseSuccess = response.data;
          UserSessionFakeService.setToken(data.payload.token);
          return data.payload.user;
        });
    }

    return this.api.core
      .post('/auth/login', {
        email: credentials.email,
        password: credentials.password,
      })
      .then((response: any) => response.data.payload);
  }

  logout(): Promise<any> {
    if (Faker.isEnabled()) {
      return UserSessionFakeService.logout();
    }

    return this.api.core.get('/auth/logout');
  }

  fetchAuthOptions(): Promise<{ options: LoginTypeEnum[] }> {
    if (Faker.isEnabled()) {
      return UserSessionFakeService.getAuthOptions();
    }

    return this.api.core.get('/auth/options').then((response: any) => response.data.payload);
  }

  fetchCurrentUser(): Promise<SessionUserType | null> {
    if (Faker.isEnabled()) {
      const token = UserSessionFakeService.getToken();

      return this.api.bff
        .post<IMeResponseSuccess>('/demo/me', { token })
        .then(response => {
          return response.data.payload.user;
        })
        .catch(error => {
          console.error(error);
          return null;
        });
    }

    return this.api.core.get('/users/me').then(response => response.data.payload.user);
  }

  async fetchCurrentUserOrganisation(): Promise<OrganisationType | null> {
    if (Faker.isEnabled()) {
      return UserSessionFakeService.getUserOrganisation();
    }

    try {
      const response = await this.api.core.get('/organisation');
      return response.data.payload;
    } catch (err) {
      return null;
    }
  }

  requestResetPassword(email: string) {
    return this.api.core.post('/reset-password', { email }).then(response => response.data.payload);
  }

  setPassword(password: string, token: string): Promise<SetPasswordResponseType> {
    return this.api.core.post('/reset-password/save', { password, token }).then(response => response.data.payload);
  }
}
