import { defineStore } from 'pinia';
import { DatesRangeType } from '~/shared/ui/form/date-picker/types/dates-range.type';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { Cache } from '~/shared/helpers/cache';
import { ResultsSavingType } from '@/packages/attrition/types/results-saving.type';
import { ResultsComparisonType } from '@/packages/attrition/types/results-comparison.type';
import { DatePickerOptionType } from '~/shared/ui/form/date-picker/types/date-picker-option.type';
import { useDatesStore } from '~/shared/stores/dates.store';

export const useAttritionResultsStore = defineStore('attritionResults', () => {
  // DATES
  const datesStore = useDatesStore();
  const datePickerOption = computed(() => datesStore.selectedOption);
  const dates = computed(() => datesStore.selectedDates);
  const datesDisplay = computed(() => datesStore.selectedOption?.title || '');

  function setDatePickerOption(option: DatePickerOptionType) {
    datesStore.setSelectedOption(option);
  }

  // SAVING
  const savingCache = ref(new Cache<ResultsSavingType>());
  const savingFilters = ref<FilterCriteria>({});

  const activeSavingCacheKey = computed(() => {
    return Cache.generateKey({
      d: dates.value,
      f: savingFilters.value,
    });
  });

  const activeSaving = computed(() => {
    return savingCache.value.getItem(activeSavingCacheKey.value);
  });

  const hasCachedActiveSaving = computed(() => {
    return savingCache.value.hasItem(activeSavingCacheKey.value);
  });

  function setSavingFilters(filters: FilterCriteria) {
    savingFilters.value = filters;
  }

  function cacheSaving(payload: { key: string; data: ResultsSavingType }) {
    savingCache.value.setItem(payload.key, payload.data);
  }

  // COMPARISON
  const comparisonCache = ref(new Cache<ResultsComparisonType>());
  const comparisonGrouping = ref('');
  const comparisonGroupingName = ref('');

  const activeComparisonCacheKey = computed(() => {
    return Cache.generateKey({ d: dates.value });
  });

  const activeComparison = computed(() => {
    return comparisonCache.value.getItem(activeComparisonCacheKey.value);
  });

  const hasCachedActiveComparison = computed(() => {
    return comparisonCache.value.hasItem(activeComparisonCacheKey.value);
  });

  function cacheComparison(payload: { key: string; data: ResultsComparisonType }) {
    comparisonCache.value.setItem(payload.key, payload.data);
  }

  function setComparisonGrouping(grouping: string) {
    comparisonGrouping.value = grouping;
  }

  function setComparisonGroupingName(groupingName: string) {
    comparisonGroupingName.value = groupingName;
  }

  // GROUPING
  const groupingOptions = ref<Record<string, string>[]>([]);

  function setGroupingOptions(opts: Record<string, string>[]) {
    groupingOptions.value = opts;
  }

  return {
    dates,
    datesDisplay,
    datePickerOption,
    setDatePickerOption,

    savingCache,
    savingFilters,
    activeSavingCacheKey,
    activeSaving,
    hasCachedActiveSaving,
    setSavingFilters,
    cacheSaving,

    comparisonCache,
    comparisonGrouping,
    comparisonGroupingName,
    activeComparisonCacheKey,
    activeComparison,
    hasCachedActiveComparison,
    cacheComparison,
    setComparisonGrouping,
    setComparisonGroupingName,

    groupingOptions,
    setGroupingOptions,
  };
});
