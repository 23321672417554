import { defineStore } from 'pinia';
import { PermissionEnum } from '@/packages/user/enums/permission.enums';
import { SessionUserType } from '@/packages/user/types/auth.types';
import { OrganisationType } from '@/packages/user/types/organisation.types';
import { OrganisationSubscriptionEnum } from '@/packages/user/enums/organisation.enums';
import { userCan } from '@/packages/user/helpers/permission.helpers';

export const useSessionStore = defineStore('session', {
  state: () => ({
    user: null as SessionUserType | null,
    organisation: null as OrganisationType | null,
  }),

  getters: {
    isAuthorized(state): boolean {
      return !!state.user;
    },

    hasAnalyticsAccess(state): boolean {
      const uid = state.organisation?.subscription?.uid;

      if (!state.user || !uid) return false;

      const subscriptions = [OrganisationSubscriptionEnum.INTEGRATION_ANALYTICS];

      const hasPermissions = [
        PermissionEnum.ATTRITION_ALERT_ALERT_VIEW,
        PermissionEnum.ATTRITION_DRIVER_VIEW,
        PermissionEnum.ATTRITION_HEATMAP_VIEW,
        PermissionEnum.ATTRITION_MONETARY_RESULT_VIEW,
        PermissionEnum.ATTRITION_COMPARISON_RESULT_VIEW,
        PermissionEnum.PRODUCTIVITY_HEATMAP_VIEW,
        PermissionEnum.PRODUCTIVITY_TREND_VIEW,
        PermissionEnum.INTEGRATION_VIEW,
        PermissionEnum.SEGMENT_VIEW,
      ].some(permission => userCan(permission));

      return subscriptions.includes(uid) && hasPermissions;
    },

    hasOrganisation(state): boolean {
      return !!state.organisation;
    },
  },

  actions: {
    setUser({ user }: { user: SessionUserType | null }) {
      this.user = user;
    },

    setOrganisation(organisation: OrganisationType | null) {
      this.organisation = organisation;
    },
  },
});
