import dayjs from '~/shared/utils/dayjs';
// TODO: Review and del
// import utcPlugin from 'dayjs/plugin/utc';
import { IntegrationDataTypeEnum } from '@/packages/integration/enums/integration-data-type.enum';
import { TrendPeriodGroupingEnum } from '~/shared/ui/chart/trend/enums/trend.enums';
import { TrendFetchDto } from '~/shared/ui/chart/trend/types/trend-request.types';
import { Trend, TrendMetricDescription } from '~/shared/ui/chart/trend/types/trend.types';
import { generateTrend } from '../helpers/trend-generator';

// dayjs.extend(utcPlugin);

const metricsArray = [
  {
    name: 'Avg. calls per person',
    uid: 'avgCalls',
    group: 'Main metric',
    dataTypeUid: IntegrationDataTypeEnum.VIDEO_CALL,
    selectedByDefault: true,
  },
  {
    name: 'Avg. group calls per person',
    uid: 'avgGroupCalls',
    group: 'By group and one-on-one',
    dataTypeUid: IntegrationDataTypeEnum.VIDEO_CALL,
    selectedByDefault: false,
  },
  {
    name: 'Avg. one-on-one calls per person',
    uid: 'avgOneOnOneCalls',
    group: 'By group and one-on-one',
    dataTypeUid: IntegrationDataTypeEnum.VIDEO_CALL,
    selectedByDefault: false,
  },
];

const metricsDescription: Record<IntegrationDataTypeEnum, TrendMetricDescription[]> = {
  [IntegrationDataTypeEnum.VIDEO_CALL]: metricsArray,
  [IntegrationDataTypeEnum.CHAT_MESSAGE]: metricsArray,
  [IntegrationDataTypeEnum.MAIL_MESSAGE]: metricsArray,
  [IntegrationDataTypeEnum.HRIS]: metricsArray,
  [IntegrationDataTypeEnum.TWITS]: metricsArray,
  [IntegrationDataTypeEnum.CALENDAR_EVENT]: metricsArray,
  [IntegrationDataTypeEnum.CRM]: metricsArray,
};

export class TrendFakeService {
  public static fetchMetricsDescription() {
    return Promise.resolve(metricsDescription);
  }

  public static fetchMetricsData(options: TrendFetchDto): Promise<Record<string, Trend>> {
    const trends: Record<string, Trend> = {};

    const diffUnit = options.periodGrouping === TrendPeriodGroupingEnum.MONTHLY ? 'month' : 'week';
    const starts = dayjs.utc(options.period.starts);
    const ends = dayjs.utc(options.period.ends);
    const length = Math.ceil(ends.diff(starts, diffUnit, true));
    const startPoint = options.periodGrouping === TrendPeriodGroupingEnum.MONTHLY ? starts.month() : starts.isoWeek();

    options.metrics.forEach(metricUid => {
      trends[metricUid] = generateTrend({
        metricUid,
        length,
        year: starts.year(),
        startPoint,
        min: 1000,
        max: 12000,
      });
    });

    return Promise.resolve(trends);
  }
}
