import { UiFacade } from '~/shared/ui/ui.facade';

export default defineNuxtPlugin(() => {
  const ui = new UiFacade();
  return {
    provide: {
      ui,
    },
  };
});
