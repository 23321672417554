export enum FeelValuedEnum {
  STRONGLY_DISAGREE = 'STRONGLY_DISAGREE',
  DISAGREE = 'DISAGREE',
  SLIGHTLY_DISAGREE = 'SLIGHTLY_DISAGREE',

  NEUTRAL = 'NEUTRAL',

  SLIGHTLY_AGREE = 'SLIGHTLY_AGREE',
  AGREE = 'AGREE',
  STRONGLY_AGREE = 'STRONGLY_AGREE',

  NOT_SELECTED = 'NOT_SELECTED',
}
