import { defineStore } from 'pinia';
import { ExpansionPanelConfig } from '~/shared/ui/expansion-panel/expansion-panel.inventory';

export const useExpansionPanelStore = defineStore('expansionPanel', {
  state: () => ({
    configurations: {} as Record<string, ExpansionPanelConfig>,
  }),

  actions: {
    addConfiguration(options: ExpansionPanelConfig) {
      this.configurations[options.id] = options;
    },

    deleteConfiguration(id: string) {
      delete this.configurations[id];
    },
  },
});
