<template>
  <v-snackbar class="message-snackbar" :color="color" :model-value="visible">
    {{ message }}

    <template #actions>
      <Icon
        name="eva:close-outline"
        size="16"
        class="snackbar-button text-light-100"
        color="white"
        @click="close"
      ></Icon>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import theme from '~/shared/theme/default.theme';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { SnackbarMessageTypeEnum } from '~/shared/ui/snackbar/snackbar.enum';

export default defineComponent({
  name: 'SnackbarMessage',

  props: {
    message: {
      type: String,
      default: '',
    },

    type: {
      type: String as PropType<SnackbarMessageTypeEnum | null>,
      default: SnackbarMessageTypeEnum.INFO,
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  computed: {
    color(): string {
      switch (this.type) {
        case SnackbarMessageTypeEnum.INFO:
          return theme.grey[100];

        case SnackbarMessageTypeEnum.ERROR:
          return theme.grey[100];

        case SnackbarMessageTypeEnum.SUCCESS:
          return theme.green[100];
      }

      return theme.grey[100];
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
.message-snackbar {
  margin-bottom: 1em;

  & .snackbar-button {
    padding: 0 0;
    margin-right: 8px;
    opacity: 0.5;
    transition: 0.3s var(--timing-fn--default);

    &:hover {
      opacity: 1;
    }
  }
}
</style>
