export enum DriverEnum {
  PROMOTION = 'PROMOTION',
  MANAGER = 'MANAGER',
  BURNOUT = 'BURNOUT',
  OPPORTUNITIES_FOR_DEVELOPMENT = 'OPPORTUNITIES_FOR_DEVELOPMENT',
  COLLEAGUES = 'COLLEAGUES',
  PAY = 'PAY',
  LEADERSHIP = 'LEADERSHIP',

  COMMUNICATION = 'COMMUNICATION',
  MANAGER_TENURE = 'MANAGER_TENURE',
  STAKEHOLDERS = 'STAKEHOLDERS',
  ZOOM = 'ZOOM',
  TRAINING = 'TRAINING',
}
