import { AppService } from '~/app/app.service';
import { Faker } from '../fake-data/faker';
import { FilterFakeService } from '../fake-data/filter/filter.fake-service';
import { FilterOptionType } from './types/filter-option.type';
import { FilteredUsersType } from './types/filtered-users.type';
import { FilteredUsersRequestDto, FiltersRequestDto } from './types/filters-request-dto.type';

export class FilterService extends AppService {
  fetchFilters(dto: FiltersRequestDto): Promise<FilterOptionType[]> {
    if (Faker.isEnabled()) {
      return Promise.resolve(FilterFakeService.getFilters());
    }

    return this.api.core
      .post('/integrations/analytics/filter', dto)
      .then(response => response.data.payload.filters);
  }

  fetchFilteredUsers(dto: FilteredUsersRequestDto): Promise<FilteredUsersType> {
    if (Faker.isEnabled()) {
      return FilterFakeService.getFilteredUsers();
    }

    return this.api.core
      .post('/integrations/analytics/filter/users', dto)
      .then((response: any) => response.data.payload);
  }
}
