import { IntegrationEnum } from '@/packages/integration/enums/integration.enum';
import { IntegrationsStatusType, IntegrationsAvailableDatesType } from '@/packages/integration/types/integration.types';

export class IntegrationFakeService {
  static getAvailableDates(): Promise<IntegrationsAvailableDatesType> {
    return Promise.resolve({
      ALL: {
        uid: 'ALL',
        min: '2021-08-16T00:00:00.000Z',
        max: '2022-12-02T00:00:00.000Z',
      },

      [IntegrationEnum.BAMBOOHR]: {
        uid: IntegrationEnum.BAMBOOHR,
        min: null,
        max: null,
      },

      [IntegrationEnum.GOOGLE_CALENDAR]: {
        uid: IntegrationEnum.GOOGLE_CALENDAR,
        min: null,
        max: null,
      },

      [IntegrationEnum.GOOGLE_MAIL]: {
        uid: IntegrationEnum.GOOGLE_MAIL,
        min: null,
        max: null,
      },

      [IntegrationEnum.GOOGLE_MEET]: {
        uid: IntegrationEnum.GOOGLE_MEET,
        min: null,
        max: null,
      },

      [IntegrationEnum.MATTERMOST]: {
        uid: IntegrationEnum.MATTERMOST,
        min: null,
        max: null,
      },

      [IntegrationEnum.SLACK]: {
        uid: IntegrationEnum.SLACK,
        min: null,
        max: null,
      },

      [IntegrationEnum.ZOOM]: {
        uid: IntegrationEnum.ZOOM,
        min: null,
        max: null,
      },

      [IntegrationEnum.MICROSOFT_CALENDAR]: {
        uid: IntegrationEnum.MICROSOFT_CALENDAR,
        min: null,
        max: null,
      },

      [IntegrationEnum.MICROSOFT_MAIL]: {
        uid: IntegrationEnum.MICROSOFT_MAIL,
        min: null,
        max: null,
      },

      [IntegrationEnum.MICROSOFT_VIDEO_CALLS]: {
        uid: IntegrationEnum.MICROSOFT_VIDEO_CALLS,
        min: null,
        max: null,
      },
    });
  }

  static getStatus(): Promise<IntegrationsStatusType> {
    return Promise.resolve({
      [IntegrationEnum.MATTERMOST]: {
        isEnabled: false,
        dates: {
          created: '2021-09-01T00:00:00.000Z',
          connected: '2021-06-11T16:56:15.312Z',
        },
      },
      [IntegrationEnum.ZOOM]: {
        isEnabled: true,
        dates: {
          created: '2021-09-01T00:00:00.000Z',
          connected: '2021-09-15T09:47:33.642Z',
        },
      },
      [IntegrationEnum.SLACK]: {
        isEnabled: true,
        dates: {
          created: '2021-09-01T00:00:00.000Z',
          connected: '2021-11-08T17:08:17.599Z',
        },
      },
      [IntegrationEnum.GOOGLE]: {
        isEnabled: true,
        dates: {
          created: '2021-09-01T00:00:00.000Z',
          connected: '2021-09-15T08:28:15.703Z',
        },
        products: {
          [IntegrationEnum.GOOGLE_MAIL]: { isEnabled: true },
          [IntegrationEnum.GOOGLE_MEET]: { isEnabled: true },
          [IntegrationEnum.GOOGLE_CALENDAR]: { isEnabled: true },
        },
      },
      [IntegrationEnum.MICROSOFT]: {
        isEnabled: true,
        dates: {
          created: '2021-10-01T00:00:00.000Z',
          connected: '2021-11-08T17:07:33.993Z',
        },
        products: {
          [IntegrationEnum.MICROSOFT_MAIL]: { isEnabled: true },
          [IntegrationEnum.MICROSOFT_CALENDAR]: { isEnabled: true },
          [IntegrationEnum.MICROSOFT_VIDEO_CALLS]: {
            isEnabled: true,
          },
          [IntegrationEnum.MICROSOFT_MESSAGES]: { isEnabled: true },
        },
      },
      [IntegrationEnum.BAMBOOHR]: {
        isEnabled: true,
        dates: {
          created: '2021-09-01T00:00:00.000Z',
          connected: '2022-02-05T13:59:55.809Z',
        },
      },
      [IntegrationEnum.WORKDAY]: {
        isEnabled: true,
        dates: {
          created: '2021-09-01T00:00:00.000Z',
          connected: '2021-11-08T17:08:17.599Z',
        },
      },
      [IntegrationEnum.HIBOB]: {
        isEnabled: true,
        dates: {
          created: '2021-09-01T00:00:00.000Z',
          connected: '2021-11-08T17:08:17.599Z',
        },
      },
      [IntegrationEnum.SALESFORCE]: {
        isEnabled: true,
        dates: {
          created: '2021-09-01T00:00:00.000Z',
          connected: '2021-11-08T17:08:17.599Z',
        },
      },
    });
  }
}
