import dayjs from '~/shared/utils/dayjs';

export enum MonthFormat {
  FULL = 'MMMM',
  ABBREVIATED = 'MMM',
  ONE_DIGIT = 'MM',
  TWO_DIGIT = 'M',
}

export enum YearFormat {
  FULL = 'YYYY',
  TWO_DIGIT = 'YY',
}

export type Options = {
  yearShow?: 'never' | 'always';
  yearFormat?: YearFormat;
  monthFormat?: MonthFormat;
};

export default function formatMonth(date: any, options?: Options) {
  date = dayjs.utc(date);

  if (!date.isValid()) {
    return '–';
  }

  const {
    yearShow = 'always',
    yearFormat = YearFormat.FULL,
    monthFormat = MonthFormat.ABBREVIATED,
  } = options || {};

  let format = monthFormat as string;

  if (yearShow === 'always') {
    format += ' ' + yearFormat;
  }

  return date.format(format);
}
