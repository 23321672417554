export enum IntegrationEnum {
  MATTERMOST = 'MATTERMOST',
  SLACK = 'SLACK',
  TWITTER = 'TWITTER',
  BAMBOOHR = 'BAMBOOHR',
  ZOOM = 'ZOOM',

  HIBOB = 'HIBOB',
  WORKDAY = 'WORKDAY',

  GOOGLE = 'GOOGLE',
  GOOGLE_MAIL = 'GOOGLE_MAIL',
  GOOGLE_MEET = 'GOOGLE_MEET',
  GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',

  SALESFORCE = 'SALESFORCE',

  MICROSOFT = 'MICROSOFT',
  MICROSOFT_CALENDAR = 'MICROSOFT_CALENDAR',
  MICROSOFT_MAIL = 'MICROSOFT_MAIL',
  MICROSOFT_VIDEO_CALLS = 'MICROSOFT_VIDEO_CALLS',
  MICROSOFT_MESSAGES = 'MICROSOFT_MESSAGES',
}
