import { IntegrationEnum } from '@/packages/integration/enums/integration.enum';
import { Trend, TrendMetricDescription } from '~/shared/ui/chart/trend/types/trend.types';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { DatesRangeType } from '~/shared/ui/form/date-picker/types/dates-range.type';
import { defineStore } from 'pinia';
import { DatePickerOptionType } from '~/shared/ui/form/date-picker/types/date-picker-option.type';
import { Cache } from '~/shared/helpers/cache';
import { TrendTabEnum } from '~/shared/ui/chart/trend/enums/trend.enums';
import { IntegrationDataTypeEnum } from '@/packages/integration/enums/integration-data-type.enum';
import { useDatesStore } from '~/shared/stores/dates.store';

type CacheKeyOptions = {
  criteria: FilterCriteria | undefined;
  dates: DatesRangeType;
  integrations: string[];
  metric: string;
};

export const useProductivityTrendStore = defineStore('productivityTrend', () => {
  const datesStore = useDatesStore();

  // COMMON CONFIGURATION

  const selectedTab = ref<TrendTabEnum>(TrendTabEnum.SEGMENT);
  const selectedIntegrations = ref<IntegrationEnum[]>([]);
  const selectedDatePickerOption = computed(() => datesStore.selectedOption);
  const selectedDatesPeriod = computed<DatesRangeType | undefined>(() => {
    return selectedDatePickerOption.value?.dates;
  });
  function storeSelectedDatePickerOption(option: DatePickerOptionType) {
    datesStore.setSelectedOption(option);
  }

  // SEGMENTS COMPARISON CONFIGURATION

  const tabSegment = ref({
    segments: [] as (FilterCriteria | undefined)[],
    metric: '',
  });

  // METRICS COMPARISON CONFIGURATION

  const tabMetric = ref({
    segment: undefined as FilterCriteria | undefined,
    metrics: [] as string[],
  });

  // INVENTORY

  const metrics = ref<Record<IntegrationDataTypeEnum, TrendMetricDescription[]>>(
    {} as Record<IntegrationDataTypeEnum, TrendMetricDescription[]>
  );

  const metricsFlat = computed(() => Object.values(metrics.value).flat());

  // CACHE

  const trendCache = new Cache<Trend>();

  function getTrendCacheKey({ criteria, dates, integrations, metric }: CacheKeyOptions) {
    return Cache.generateKey({
      criteria,
      dates,
      integrations,
      metric,
    });
  }

  function getTrend(key: string): Trend | undefined {
    return trendCache.getItem(key);
  }

  function hasTrend(key: string): boolean {
    return trendCache.hasItem(key);
  }

  function cacheTrend(key: string, trend: Trend) {
    trendCache.setItem(key, trend);
  }

  return {
    storeSelectedDatePickerOption,
    selectedDatePickerOption,
    selectedDatesPeriod,
    selectedTab,
    selectedIntegrations,

    tabSegment,
    tabMetric,

    metrics,
    metricsFlat,

    getTrend,
    hasTrend,
    getTrendCacheKey,
    cacheTrend,
  };
});
