import { useSnackbarStore } from './snackbar.store';
import { AppFacade } from '~/app/app.facade';
import { AxiosError } from 'axios';
import { debounce } from 'lodash-es';
import { SnackbarMessageTypeEnum } from '~/shared/ui/snackbar/snackbar.enum';
import { getApiErrorMessage } from '~/shared/api/helpers/get-api-error-message';

export class SnackbarFacade extends AppFacade {
  public hideDebounce: () => void;

  constructor() {
    super();
    this.hideDebounce = debounce(this.hide, 6000);
  }

  showError(text: string) {
    this.show(text, SnackbarMessageTypeEnum.ERROR);
  }

  showInfo(text: string) {
    this.show(text, SnackbarMessageTypeEnum.ERROR);
  }

  showSuccess(text: string) {
    this.show(text, SnackbarMessageTypeEnum.SUCCESS);
  }

  showAPIError(apiError: AxiosError) {
    if (!apiError) {
      this.showError('Unknown error');
      return;
    }

    this.showError(getApiErrorMessage(apiError));
  }

  hide() {
    this.store.hide();
  }

  private show(text: string, type: SnackbarMessageTypeEnum) {
    this.store.show({ text, type });
    this.hideDebounce();
  }

  get visible() {
    return this.store.visible;
  }

  get text(): string | null {
    return this.store.text;
  }

  get type(): SnackbarMessageTypeEnum | null {
    return this.store.type;
  }

  private get store() {
    return useSnackbarStore();
  }
}
