import { RestApi } from '~/shared/api/api';
import { AxiosError } from 'axios';
import { useApi } from '~/shared/api/api.composable';
import { isUnauthorizedError } from '~/shared/api/helpers/is-unathorized-error';

const restApis: RestApi[] = [];

const interceptors = new Map<RestApi, number>();
let userWantTo: { path: string } | null = null;

let redirecting = false;

export default defineNuxtRouteMiddleware(async (to, from) => {
  const app = useNuxtApp();
  const api = useApi();

  userWantTo = to;

  const toApp = to.meta.layout === 'dashboard';
  const fromApp = from.meta.layout === 'dashboard';

  if (restApis.length === 0) {
    let apiKey: keyof typeof api;
    for (apiKey in api) {
      if (api[apiKey] instanceof RestApi) {
        restApis.push(api[apiKey]);
      }
    }
  }

  // skip routing between pages, which do not need auth
  if (!toApp && !fromApp) {
    return;
  }

  // user going to page what do not need auth
  if (!toApp) {
    interceptors.forEach((id, restApi) => {
      restApi.removeResponseInterceptor(id);
    });

    interceptors.clear();

    return;
  }

  // add unathorized interceptors for rest apis
  if (interceptors.size === 0) {
    restApis.forEach(restApi => interceptors.set(restApi, restApi.addResponseInterceptor(unauthorizedInterceptor)));
  }

  // get current user
  if (!app.$user.session.isAuthorized) {
    try {
      await app.$user.fetchAndStoreCurrentUser();
    } catch (e) {
      console.error(e);
    }

    // user not logged in
    if (!app.$user.session.isAuthorized) {
      return navigateTo(getRouteWithRedirect(false));
    }
  }

  if (!app.$user.session.hasOrganisation) {
    await app.$user.fetchAndStoreCurrentOrganisation();
  }

  if (!app.$user.session.hasAnalyticsAccess) {
    return;
  }

  if (!app.$integration.isAvailableDatesFetched) {
    await app.$integration.fetchAndStoreAvailableDates();
  }

  if (!app.$integration.isStatusFetched) {
    await app.$integration.fetchAndStoreStatus();
  }

  async function unauthorizedInterceptor(error: AxiosError): Promise<AxiosError> {
    if (!redirecting && isUnauthorizedError(error)) {
      redirecting = true;
      await navigateTo(getRouteWithRedirect(app.$user.session.isAuthorized));
      redirecting = false;
    }

    return Promise.reject(error);
  }

  function getRouteWithRedirect(loggedIn: boolean) {
    return {
      path: loggedIn ? '/logout' : '/login',
      query: { redirectTo: userWantTo?.path },
    };
  }
});
