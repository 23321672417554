<template>
  <div class="error-page">
    <AppHeaderLogo />

    <MessageFullScreen v-if="error.statusCode === 404">
      <template #illustration>
        <IllustrationNotFound class="error-page__not-found" />
      </template>

      <template #subtitle>Oops! We can’t find that page</template>

      <template v-if="isAuthorized" #btn>
        <NuxtLink to="/">
          <BtnBase color="blue" width="240px" size="L"> Homepage </BtnBase>
        </NuxtLink>
      </template>
    </MessageFullScreen>

    <MessageFullScreen v-else-if="error.statusCode === 401">
      <template #illustration>
        <IllustrationNetworkError />
      </template>

      <template #title>Forbidden</template>

      <template #subtitle>You need to sign in to continue</template>

      <template v-if="isAuthorized" #btn>
        <NuxtLink to="/login">
          <BtnBase color="blue" width="240px" size="L"> Sign in form </BtnBase>
        </NuxtLink>
      </template>
    </MessageFullScreen>

    <MessageFullScreen v-else>
      <template #illustration>
        <IllustrationNetworkError />
      </template>

      <template #title>Error</template>

      <template #subtitle>{{ error.message }}</template>

      <template #btn>
        <BtnBase color="blue" width="240px" size="L" @click="clearError()"> Ok </BtnBase>
      </template>
    </MessageFullScreen>
  </div>
</template>

<script lang="ts" setup>
import AppHeaderLogo from '~/shared/ui/app/app-header-logo.vue';
import BtnBase from '~/shared/ui/btn/btn-base.vue';
import MessageFullScreen from '~/shared/ui/message/message-full-screen.vue';
import IllustrationNetworkError from '~/shared/ui/illustration/illustration-network-error.vue';
import IllustrationNotFound from '~/shared/ui/illustration/illustration-not-found.vue';

defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const app = useNuxtApp();

const isAuthorized = computed(() => app.$user.session.isAuthorized);

onMounted(() => {
  app.$user.fetchAndStoreCurrentUser();
});
</script>

<style lang="scss">
.error-page {
  &__not-found {
    height: 250px !important;
    width: 500px !important;
  }
}
</style>
