export default {
  transparent: 'transparent',

  'current-color': 'currentColor',

  light: {
    '100': '#FFFFFF',
  },

  icon: {
    '100': '#8E97A1',
  },

  grey: {
    '100': '#212429',
    '60': '#7A7C7F',
    '32': '#B8B9BA',
    '12': '#E4E4E5',
    '8': '#EEEEEE',
    '4': '#F6F6F7',
    '2': '#FBFBFB',
  },

  'grey-blue': {
    '100': '#9BAABD',
    '32': '#DFE4E9',
  },

  blue: {
    '300': '#1B4987',
    '200': '#2565BA',
    '100': '#2F80ED',
    '20': '#D5E6FB',
    '12': '#E6EFFC',
    '8': '#EFF5FE',
    '4': '#F7FAFE',
  },

  green: {
    '300': '#2E8752',
    '200': '#3BAD69',
    '100': '#48D480',
    '8': '#F1FCF5',
    '4': '#F8FDFA',
  },

  orange: {
    '300': '#B27519',
    '200': '#D98E1E',
    '100': '#FFA724',
    '8': '#FFF8EE',
    '4': '#FFFCF6',
  },

  red: {
    '300': '#B24545',
    '200': '#D95454',
    '100': '#FF6363',
    '8': '#FFF3F3',
    '4': '#FFF9F9',
  },

  purple: {
    '100': '#BE5EF9',
  },

  primary: {
    base: '#2F80ED',
    darken1: '#1B4987',
    lighten1: '#7AAEF4',
    lighten2: '#BCD6F9',
    lighten3: '#E6F0FD',
  },

  secondary: '#F3F5FF',
  accent: '#F26862',
  // green: '#32AD6D',
  yellow: '#ffba00',
  // red: '#C9555C',
  alert: '#f37d04',
  reward: '#E8A32C',
  waiting: '#de9b1e',
  avatar: '#525B65',
  underline: '#DFE4F0',
  quote: '#FAFAFC',

  hoverPrimary: '#8D4023',
  hoverUnderline: '#ECDCD5',
  hoverBackground: '#F9F2F1',

  alertRed: '#fdf7f8',
  alertNeutral: '#fdf7f8',

  admin: '#958457',

  primaryText: '#212429',
  secondaryText: '#92A1B2',

  heatmapRed: '#FF6363',
  heatmapYellow: '#FFA724',
  heatmapGreen: '#48D480',

  disabledBackground: '#EBEBEB',
  disabledText: '#B7B7B7',

  dividerSolid: '#EDEDED',
  tag: '#FAFAFC',
  input: '#F7F8FA',
  chartGrid: '#F3F3F3',
  placeholder: '#E9E9E9',
  inputPlaceholder: '#BDC2C8',
  toggle: '#E8EFFF',

  sidebarBackground: '#F7F9FA',
  analyticsBackground: '#f6f7fa',
  secondaryBackground: '#F2F5FE',

  indicatorWaiting: '#E08F15',

  tableRow: '#FAFAFA',
};
