import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(nuxtApp => {
  const {
    public: { googleAnalyticsId: id },
  } = useRuntimeConfig();

  if (!id) {
    return console.warn('[gtag] Gtag not loaded');
  }

  nuxtApp.vueApp.use(
    createGtm({
      id,
      vueRouter: useRouter(),
    })
  );
});
