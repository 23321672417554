import { SurveyStatusEnum } from '~/packages/attrition/enums/survey-status.enum';
import { SurveyType } from '~/packages/attrition/types/survey-response/survey.type';
import { SurveyTypeEnum } from '~/packages/attrition/enums/survey-type.enum';
import { SurveyPageUidTypeEnum } from '~/packages/attrition/enums/survey-page-uid-type.enum';
import { SurveyAnswerTypeEnum } from '~/packages/attrition/enums/survey-answer-type.enum';

export class SurveyFakeData {
  public static getInitialSurvey(): Promise<SurveyType> {
    return Promise.resolve({
      survey: {
        id: '123',
        type: SurveyTypeEnum.ATTRITION_EMPLOYEE_FIRST,
        seconds: 60,
        questions: {
          total: 5,
          related: 2,
        },
      },

      user: {
        status: SurveyStatusEnum.SENT,
      },

      organisation: {
        id: '1',
        name: 'Performetry',
        ssoLoginLink: window.location.origin + '/survey/a/id',
      },

      pages: [
        {
          type: SurveyPageUidTypeEnum.QUESTION,
          questions: [
            {
              id: '1',
              uuid: '1',
              title: 'I feel valued at {company}',
              subtitle: 'You previously identified that you had an intention to stay at {company}',
              required: true,
              replacements: {
                company: {
                  type: 'STRONG',
                  data: 'Pied Piper',
                },
              },
              answer: {
                id: '1',
                type: SurveyAnswerTypeEnum.RADIO_SCALE,
                values: [
                  {
                    id: '1',
                    title: 'Strongly disagree',
                    value: 1,
                    options: {
                      relatedQuestionIds: ['2'],
                    },
                  },
                  {
                    id: '2',
                    title: 'Disagree',
                    value: 2,
                  },
                  {
                    id: '3',
                    title: 'Slightly disagree',
                    value: 3,
                  },
                  {
                    id: '4',
                    title: 'Neutral',
                    value: 4,
                  },
                  {
                    id: '5',
                    title: 'Slightly agree',
                    value: 5,
                  },
                  {
                    id: '6',
                    title: 'Agree',
                    value: 6,
                  },
                  {
                    id: '7',
                    title: 'Strongly agree',
                    value: 7,
                  },
                ],
              },
            },
            {
              id: '2',
              uuid: '2',
              title: 'I feel valued at Pied Piper',
              required: true,
              answer: {
                id: '1',
                type: SurveyAnswerTypeEnum.CHECKBOX,
                values: [
                  {
                    id: '1',
                    title: 'Strongly disagree',
                    value: 1,
                  },
                  {
                    id: '2',
                    title: 'Disagree',
                    value: 2,
                  },
                  {
                    id: '3',
                    title: 'Slightly disagree',
                    value: 3,
                  },
                  {
                    id: '4',
                    title: 'Neutral',
                    value: 4,
                  },
                  {
                    id: '5',
                    title: 'Slightly agree',
                    value: 5,
                  },
                  {
                    id: '6',
                    title: 'Agree',
                    value: 6,
                  },
                  {
                    id: '7',
                    title: 'Strongly agree',
                    value: 7,
                  },
                ],
              },
            },
            {
              id: '3',
              uuid: '3',
              title: 'Please, explain',
              required: false,
              answer: {
                id: '1',
                type: SurveyAnswerTypeEnum.LONGTEXT,
                values: [
                  {
                    id: '1',
                    title: '',
                    value: 1,
                  },
                ],
              },
            },
          ],
        },
      ],
    });
  }
}
