<template>
  <button
    class="base-btn relative flex shrink-0 cursor-pointer items-center justify-center rounded-lg transition-all duration-500"
    :class="{
      [`!px-0`]: btnStyle === 'flat' || btnStyle === 'flat-underline',
      [`${colors.text} ${colors.textHover}`]:
        btnStyle === 'outlined' || btnStyle === 'flat' || btnStyle === 'flat-underline',
      [`${colors.border} ${colors.borderHover} border-1 border-solid`]: btnStyle === 'outlined',
      [`${colors.bg} ${colors.bgHover} `]: btnStyle === 'solid',

      'cursor-progress': loading,
      '!cursor-default': disabled,

      'w-full': fullWidth,

      'w-8 max-w-[2rem] !px-0': icon && size === 'S',
      'w-11 max-w-[2.75rem] !px-0': icon && size === 'M',
      'w-15 max-w-[3.75rem] !px-0': icon && size === 'L',

      'text-button-s h-8 px-3': size === 'S',
      'text-button-m h-11 px-6': size === 'M',
      'text-button-l h-[3.75rem] px-8': size === 'L',
    }"
    :disabled="disabled || loading"
    :loading="loading"
    :type="type"
    :style="{
      width,
    }"
  >
    <div v-if="loading" class="absolute top-0 left-0 flex h-full w-full items-center justify-center">
      <LoaderCircular size="24" />
    </div>

    <div class="mr-1 empty:hidden">
      <slot name="icon-before" />
    </div>

    <div :class="{ 'text-transparent': loading }">
      <slot name="default" />
      <div
        v-if="btnStyle === 'flat-underline'"
        class="bg-current-color absolute bottom-[6px] h-[1.5px] w-full transition-all"
        :class="underlineClass"
      />
    </div>

    <div class="ml-1 empty:hidden">
      <slot name="icon-after" />
    </div>
  </button>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import { BtnSizeEnum } from '~/shared/ui/btn/btn-size.enum';
import { BtnStyleEnum } from '~/shared/ui/btn/btn-style.enum';
import LoaderCircular from '~/shared/ui/loader/loader-circular.vue';

type ColorConfiguration = {
  text: string;
  textHover: string;
  bg: string;
  bgHover: string;
  border: string;
  borderHover: string;
};

const colorsMap = {
  blue: {
    text: 'text-blue-100 ',
    textHover: '[&:not([disabled])]:hover:text-blue-300',
    bg: 'bg-blue-100 disabled:bg-grey-8 disabled:text-grey-32 text-light-100',
    bgHover: '[&:not([disabled])]:hover:bg-blue-200 ',
    border: 'border-blue-100',
    borderHover: '[&:not([disabled])]:hover:border-blue-200',
  },
  green: {
    text: 'text-green-200',
    textHover: '[&:not([disabled])]:hover:text-green-300',
    bg: 'bg-green-100 text-light-100',
    bgHover: '[&:not([disabled])]:hover:bg-green-200',
    border: 'border-green-200',
    borderHover: '[&:not([disabled])]:hover:border-green-300',
  },
  grey: {
    text: 'text-grey-100',
    textHover: '[&:not([disabled])]:hover:text-grey-300',
    bg: 'bg-grey-100 text-light-100',
    bgHover: '[&:not([disabled])]:hover:bg-grey-200',
    border: 'border-grey-100',
    borderHover: '[&:not([disabled])]:hover:border-grey-200',
  },
  red: {
    text: 'text-red-100',
    textHover: '[&:not([disabled])]:hover:text-red-300',
    bg: 'bg-red-100 text-light-100',
    bgHover: '[&:not([disabled])]:hover:bg-red-200',
    border: 'border-red-100',
    borderHover: '[&:not([disabled])]:hover:border-red-200',
  },
  orange: {
    text: 'text-orange-100',
    textHover: '[&:not([disabled])]:hover:text-orange-300',
    bg: 'bg-orange-100 text-light-100',
    bgHover: '[&:not([disabled])]:hover:bg-orange-200',
    border: 'border-orange-100',
    borderHover: '[&:not([disabled])]:hover:border-orange-200',
  },
  'grey-blue': {
    text: 'text-grey-blue-100',
    textHover: '[&:not([disabled])]:hover:text-blue-200',
    bg: 'bg-grey-blue-100 text-light-100',
    bgHover: '[&:not([disabled])]:hover:bg-blue-200',
    border: 'border-grey-blue-100',
    borderHover: '[&:not([disabled])]:hover:border-blue-200',
  },
  'blue-secondary': {
    text: '',
    textHover: '',
    bg: 'bg-blue-8 text-blue-100',
    bgHover: '[&:not([disabled])]:hover:bg-blue-12',
    border: '',
    borderHover: '',
  },
};

export default defineComponent({
  name: 'BtnBase',

  components: { LoaderCircular },

  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: '',
    },

    size: {
      type: String as PropType<keyof typeof BtnSizeEnum>,
      default: BtnSizeEnum.M,
    },

    btnStyle: {
      type: String as PropType<keyof typeof BtnStyleEnum>,
      default: BtnStyleEnum.solid,
    },

    icon: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String as PropType<keyof typeof colorsMap>,
      default: 'blue',
    },

    type: {
      type: String as PropType<'button' | 'reset' | 'submit'>,
      default: 'button',
    },

    underlineClass: {
      type: [String, Array, Object],
      default: '',
    },
  },

  computed: {
    colors(): ColorConfiguration {
      return colorsMap[this.color];
    },
  },
});
</script>
