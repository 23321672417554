<template>
  <svg viewBox="0 0 138 20" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="11.8064"
      height="2.62491"
      rx="1.31246"
      transform="matrix(0.643835 -0.765165 0.766921 0.641742 7.87012 18.3154)"
      fill="#FF6363"
    />
    <rect
      width="11.8064"
      height="2.62491"
      rx="1.31246"
      transform="matrix(0.643835 -0.765165 0.766921 0.641742 4.93457 13.6689)"
      fill="#FFA724"
    />
    <rect
      width="11.8064"
      height="2.62491"
      rx="1.31246"
      transform="matrix(0.643835 -0.765165 0.766921 0.641742 2 9.03394)"
      fill="#48D480"
    />
    <path
      d="M32.1851 5.16575C33.0414 5.16575 33.7827 5.30847 34.4088 5.59392C35.0442 5.87937 35.5322 6.28453 35.8729 6.80939C36.2136 7.33425 36.384 7.9558 36.384 8.67403C36.384 9.38306 36.2136 10.0046 35.8729 10.5387C35.5322 11.0635 35.0442 11.4687 34.4088 11.7541C33.7827 12.0304 33.0414 12.1685 32.1851 12.1685H30.2376V14.8343H28V5.16575H32.1851ZM32.0608 10.3453C32.733 10.3453 33.244 10.2026 33.5939 9.91713C33.9438 9.62247 34.1188 9.2081 34.1188 8.67403C34.1188 8.13076 33.9438 7.71639 33.5939 7.43094C33.244 7.13628 32.733 6.98895 32.0608 6.98895H30.2376V10.3453H32.0608Z"
      fill="#333333"
    />
    <path
      d="M45.4676 13.0387V14.8343H37.9814V5.16575H45.2881V6.96133H40.2052V9.06077H44.6941V10.8011H40.2052V13.0387H45.4676Z"
      fill="#333333"
    />
    <path
      d="M53.4082 14.8343L51.5436 12.1409H51.4331H49.4856V14.8343H47.248V5.16575H51.4331C52.2894 5.16575 53.0307 5.30847 53.6568 5.59392C54.2922 5.87937 54.7802 6.28453 55.1209 6.80939C55.4616 7.33425 55.632 7.9558 55.632 8.67403C55.632 9.39226 55.457 10.0138 55.1071 10.5387C54.7664 11.0543 54.2784 11.4503 53.643 11.7265L55.8115 14.8343H53.4082ZM53.3668 8.67403C53.3668 8.13076 53.1918 7.71639 52.8419 7.43094C52.492 7.13628 51.981 6.98895 51.3088 6.98895H49.4856V10.3591H51.3088C51.981 10.3591 52.492 10.2118 52.8419 9.91713C53.1918 9.62247 53.3668 9.2081 53.3668 8.67403Z"
      fill="#333333"
    />
    <path
      d="M59.6423 6.96133V9.51657H64.1175V11.3122H59.6423V14.8343H57.4048V5.16575H64.7114V6.96133H59.6423Z"
      fill="#333333"
    />
    <path
      d="M70.8141 15C69.8104 15 68.9034 14.7836 68.0931 14.3508C67.292 13.918 66.6613 13.3241 66.2009 12.5691C65.7497 11.8048 65.5241 10.9484 65.5241 10C65.5241 9.05156 65.7497 8.19982 66.2009 7.44475C66.6613 6.68048 67.292 6.08195 68.0931 5.64917C68.9034 5.21639 69.8104 5 70.8141 5C71.8178 5 72.7202 5.21639 73.5213 5.64917C74.3224 6.08195 74.9532 6.68048 75.4136 7.44475C75.874 8.19982 76.1042 9.05156 76.1042 10C76.1042 10.9484 75.874 11.8048 75.4136 12.5691C74.9532 13.3241 74.3224 13.918 73.5213 14.3508C72.7202 14.7836 71.8178 15 70.8141 15ZM70.8141 13.0939C71.385 13.0939 71.9007 12.965 72.3611 12.7072C72.8215 12.4401 73.1806 12.0718 73.4384 11.6022C73.7055 11.1326 73.839 10.5985 73.839 10C73.839 9.40147 73.7055 8.8674 73.4384 8.39779C73.1806 7.92818 72.8215 7.56446 72.3611 7.30663C71.9007 7.03959 71.385 6.90608 70.8141 6.90608C70.2432 6.90608 69.7276 7.03959 69.2672 7.30663C68.8067 7.56446 68.443 7.92818 68.176 8.39779C67.9182 8.8674 67.7893 9.40147 67.7893 10C67.7893 10.5985 67.9182 11.1326 68.176 11.6022C68.443 12.0718 68.8067 12.4401 69.2672 12.7072C69.7276 12.965 70.2432 13.0939 70.8141 13.0939Z"
      fill="#333333"
    />
    <path
      d="M83.946 14.8343L82.0814 12.1409H81.9709H80.0234V14.8343H77.7858V5.16575H81.9709C82.8272 5.16575 83.5685 5.30847 84.1946 5.59392C84.83 5.87937 85.318 6.28453 85.6587 6.80939C85.9994 7.33425 86.1698 7.9558 86.1698 8.67403C86.1698 9.39226 85.9948 10.0138 85.6449 10.5387C85.3042 11.0543 84.8162 11.4503 84.1808 11.7265L86.3493 14.8343H83.946ZM83.9046 8.67403C83.9046 8.13076 83.7296 7.71639 83.3797 7.43094C83.0298 7.13628 82.5188 6.98895 81.8466 6.98895H80.0234V10.3591H81.8466C82.5188 10.3591 83.0298 10.2118 83.3797 9.91713C83.7296 9.62247 83.9046 9.2081 83.9046 8.67403Z"
      fill="#333333"
    />
    <path
      d="M96.7409 14.8343L96.7271 9.03315L93.8818 13.8122H92.8735L90.042 9.15746V14.8343H87.9426V5.16575H89.7934L93.4122 11.174L96.9757 5.16575H98.8127L98.8404 14.8343H96.7409Z"
      fill="#333333"
    />
    <path
      d="M108.62 13.0387V14.8343H101.134V5.16575H108.441V6.96133H103.358V9.06077H107.847V10.8011H103.358V13.0387H108.62Z"
      fill="#333333"
    />
    <path
      d="M112.404 6.98895H109.31V5.16575H117.735V6.98895H114.641V14.8343H112.404V6.98895Z"
      fill="#333333"
    />
    <path
      d="M125.099 14.8343L123.235 12.1409H123.124H121.177V14.8343H118.939V5.16575H123.124C123.98 5.16575 124.722 5.30847 125.348 5.59392C125.983 5.87937 126.471 6.28453 126.812 6.80939C127.153 7.33425 127.323 7.9558 127.323 8.67403C127.323 9.39226 127.148 10.0138 126.798 10.5387C126.457 11.0543 125.969 11.4503 125.334 11.7265L127.503 14.8343H125.099ZM125.058 8.67403C125.058 8.13076 124.883 7.71639 124.533 7.43094C124.183 7.13628 123.672 6.98895 123 6.98895H121.177V10.3591H123C123.672 10.3591 124.183 10.2118 124.533 9.91713C124.883 9.62247 125.058 9.2081 125.058 8.67403Z"
      fill="#333333"
    />
    <path
      d="M133.602 11.4088V14.8343H131.364V11.3812L127.621 5.16575H129.997L132.58 9.46133L135.163 5.16575H137.359L133.602 11.4088Z"
      fill="#333333"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'AppLogo',
};
</script>
