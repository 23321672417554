import { AppService } from '~/app/app.service';
import { Faker } from '@/packages/fake-data/faker';
import { TrendFakeService } from '@/packages/fake-data/productivity/trend.fake-service';
import { IntegrationDataTypeEnum } from '@/packages/integration/enums/integration-data-type.enum';
import { Trend, TrendMetricDescription } from '~/shared/ui/chart/trend/types/trend.types';
import { TrendFetchDto } from '~/shared/ui/chart/trend/types/trend-request.types';

export class TrendsService extends AppService {
  public fetchMetricsTrend(options: TrendFetchDto): Promise<Record<string, Trend>> {
    const { period, metrics, targeting, products, periodGrouping } = options;

    if (Faker.isEnabled()) {
      return TrendFakeService.fetchMetricsData(options);
    }

    return this.api.core
      .post(`/productivity/trend`, {
        period,
        metrics,
        targeting,
        products,
        periodGrouping,
      })
      .then((response: any) => response.data.payload.data);
  }

  public fetchMetricsDescription(): Promise<
    Record<IntegrationDataTypeEnum, TrendMetricDescription[]>
  > {
    if (Faker.isEnabled()) {
      return TrendFakeService.fetchMetricsDescription();
    }

    return this.api.core
      .get(`/productivity/trend/metric`)
      .then((response: any) => response.data.payload.metrics);
  }
}
