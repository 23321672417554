import { AppFacade } from '~/app/app.facade';
import { BtnStyleEnum } from '~/shared/ui/btn/btn-style.enum';
import { BtnSizeEnum } from '~/shared/ui/btn/btn-size.enum';
import formatDate from '~/shared/helpers/date/format/format-date';
import formatMonth from '~/shared/helpers/date/format/format-month';
import { SnackbarFacade } from '~/shared/ui/snackbar/snackbar.facade';
import { ExpansionPanelFacade } from '~/shared/ui/expansion-panel/expansion-panel.facade';
import colors from '~/shared/theme/default.theme';

export class UiFacade extends AppFacade {
  // enums
  public readonly BtnStyleEnum = BtnStyleEnum;
  public readonly BtnSizeEnum = BtnSizeEnum;

  // helpers
  public readonly formatDate = formatDate;
  public readonly formatMonth = formatMonth;

  // modules
  public readonly snackbar = new SnackbarFacade();
  public readonly expansionPanel = new ExpansionPanelFacade();

  // theme
  public colors;

  constructor() {
    super();
    this.colors = colors;
  }
}
