<template>
  <div class="full-screen-message">
    <ContainerBase>
      <div class="full-screen-message__illustration flex justify-center">
        <slot name="illustration" />
      </div>

      <div class="full-screen-message__title text-title-h2 md:text-title-h1">
        <slot name="title" />
      </div>

      <div class="full-screen-message__subtitle text-body-m">
        <slot name="subtitle" />
      </div>

      <div v-if="$slots.btn" class="full-screen-message__btn flex justify-center">
        <slot name="btn" />
      </div>

      <DividerLine v-if="$slots.footer" class="mt-5 md:mt-8"></DividerLine>

      <div v-if="$slots.footer" class="full-screen-message__footer">
        <slot name="footer" />
      </div>
    </ContainerBase>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContainerBase from '~/shared/ui/container/container-base.vue';
import DividerLine from '~/shared/ui/divider/divider-line.vue';

export default defineComponent({
  name: 'MessageFullScreen',

  components: { ContainerBase, DividerLine },
});
</script>

<style lang="scss">
.full-screen-message {
  text-align: center;
  margin-bottom: var(--margin-l);

  --margin-l: 32px;
  --margin-s: 16px;

  @media (max-width: theme('screens.md')) {
    --margin-l: 20px;
    --margin-s: 8px;
  }

  &__illustration {
    img,
    svg {
      width: 180px;
      height: 180px;
    }
  }

  &__title {
    margin: var(--margin-s) 0;
  }

  &__btn {
    margin: var(--margin-l) 0;
  }

  &__footer {
    margin-top: var(--margin-l);
  }
}
</style>
