export enum RevenuePermissionEnum {
  REVENUE_PIPELINE_OVERVIEW = 'REVENUE_PIPELINE_OVERVIEW',
  REVENUE_SALES_REP_OVERVIEW = 'REVENUE_SALES_REP_OVERVIEW',
  REVENUE_SALES_RATIO_OVERVIEW = 'REVENUE_SALES_RATIO_OVERVIEW',
  REVENUE_TOP_PERFORMER_RATIO_OVERVIEW = 'REVENUE_TOP_PERFORMER_RATIO_OVERVIEW',
  REVENUE_MANAGER_RATIO_OVERVIEW = 'REVENUE_MANAGER_RATIO_OVERVIEW',
  REVENUE_MANAGER_EFFICIENCY_OVERVIEW = 'REVENUE_MANAGER_EFFICIENCY_OVERVIEW',
  REVENUE_COMPENSATION_OVERVIEW = 'REVENUE_COMPENSATION_OVERVIEW',
}

export default {
  ...RevenuePermissionEnum,
};
