import { DriversType } from '~/packages/attrition/types/drivers.type';
import { DriverEnum } from '~/packages/attrition/enums/driver.enum';
import { DriversDetailsType } from '~/packages/attrition/types/drivers-details.type';

export class DriversFakeData {
  public static getDrivers(): Promise<DriversType> {
    return Promise.resolve({
      [DriverEnum.PROMOTION]: {
        stay: 60,
        leave: -80,
        name: 'Time passed since promotion',
        leaveTitle: 'LESS THAN 5 MONTHS',
        stayTitle: 'MORE THAN 5 MONTHS',
      },
      [DriverEnum.MANAGER]: {
        stay: 55,
        leave: -70,
        name: 'Frequency of manager promotions',
        leaveTitle: 'MORE THAN 1',
        stayTitle: 'NONE',
      },
      [DriverEnum.STAKEHOLDERS]: {
        stay: 50,
        leave: -65,
        name: 'Number of stakeholders',
        leaveTitle: 'MORE THAN 3',
        stayTitle: 'LESS THAN 3',
      },
      [DriverEnum.ZOOM]: {
        stay: 45,
        leave: -60,
        name: 'Number of one-one-one meetings with manager',
        leaveTitle: 'MORE THAN 1',
        stayTitle: 'LESS THAN 1',
      },
      [DriverEnum.COMMUNICATION]: {
        stay: 40,
        leave: -55,
        name: 'One-on-one communication with team',
        leaveTitle: 'MORE THAN 4',
        stayTitle: 'LESS THAN 4',
      },
      [DriverEnum.TRAINING]: {
        stay: 16,
        leave: -50,
        name: 'Time since last training',
        leaveTitle: 'MORE THAN 6 MONTHS',
        stayTitle: 'LESS THAN 6 MONTHS',
      },
      [DriverEnum.MANAGER_TENURE]: {
        stay: 28,
        leave: -42,
        name: 'Manager tenure',
        leaveTitle: 'MORE THAN 3 YEARS',
        stayTitle: 'LESS THAN 3 YEARS',
      },
    });
  }

  public static getDetails(): Promise<DriversDetailsType> {
    return Promise.resolve({
      [DriverEnum.PROMOTION]: {
        employeesLeave: 20,
        employeesStay: 115,
      },
      [DriverEnum.MANAGER]: {
        employeesLeave: 28,
        employeesStay: 65,
      },
      [DriverEnum.STAKEHOLDERS]: {
        employeesLeave: 25,
        employeesStay: 50,
      },
      [DriverEnum.ZOOM]: {
        employeesLeave: 23,
        employeesStay: 38,
      },
      [DriverEnum.COMMUNICATION]: {
        employeesLeave: 21,
        employeesStay: 26,
      },
      [DriverEnum.TRAINING]: {
        employeesLeave: 15,
        employeesStay: 22,
      },
      [DriverEnum.MANAGER_TENURE]: {
        employeesLeave: 14,
        employeesStay: 41,
      },
    });
  }
}
