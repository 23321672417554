import { IntegrationEnum } from '@/packages/integration/enums/integration.enum';
import { IntegrationConfigType } from '@/packages/integration/types/integration.types';
import { IntegrationDataTypeEnum } from '@/packages/integration/enums/integration-data-type.enum';

export default {
  [IntegrationEnum.BAMBOOHR]: {
    dataType: [IntegrationDataTypeEnum.HRIS],
    name: 'BambooHR',
    icon: 'LogoBambooHrGlyph',
    description: 'HRIS, <br /> Analytics, Insights, Segmentation',
  },

  [IntegrationEnum.HIBOB]: {
    dataType: [IntegrationDataTypeEnum.HRIS],
    name: 'HiBob',
    icon: 'LogoHiBobGlyph',
    description: 'HRIS, <br /> Analytics, Insights, Segmentation',
  },

  [IntegrationEnum.WORKDAY]: {
    dataType: [IntegrationDataTypeEnum.HRIS],
    name: 'Workday',
    icon: 'LogoWorkdayGlyph',
    description: 'HRIS, <br /> Analytics, Insights, Segmentation',
  },

  [IntegrationEnum.ZOOM]: {
    dataType: [IntegrationDataTypeEnum.VIDEO_CALL],
    name: 'Zoom',
    icon: 'LogoZoomGlyph',
    description: 'Video <br /> Analytics, Insights',
  },

  [IntegrationEnum.SLACK]: {
    dataType: [IntegrationDataTypeEnum.CHAT_MESSAGE],
    name: 'Slack',
    icon: 'LogoSlackGlyph',
    description: 'Chat <br /> Analytics, Insights',
  },

  [IntegrationEnum.MATTERMOST]: {
    dataType: [IntegrationDataTypeEnum.CHAT_MESSAGE],
    name: 'Mattermost',
    icon: 'LogoMattermostGlyph',
    description: 'Chat <br /> Analytics, Insights',
  },

  [IntegrationEnum.GOOGLE]: {
    subProducts: [IntegrationEnum.GOOGLE_CALENDAR, IntegrationEnum.GOOGLE_MAIL, IntegrationEnum.GOOGLE_MEET],
    name: 'Google Workspace',
    icon: 'LogoGoogleGlyph',
    description: 'Email, Calendar, Video<br />Analytics, Insights',
  },

  [IntegrationEnum.GOOGLE_CALENDAR]: {
    dataType: [IntegrationDataTypeEnum.CALENDAR_EVENT],
    name: 'Google Calendar',
    icon: 'LogoGoogleCalendarGlyph',
    description: 'Calendar <br /> Analytics, Insights',
  },

  [IntegrationEnum.GOOGLE_MAIL]: {
    dataType: [IntegrationDataTypeEnum.MAIL_MESSAGE],
    name: 'Google Mail',
    icon: 'LogoGoogleMailGlyph',
    description: 'Mail <br /> Analytics, Insights',
  },

  [IntegrationEnum.GOOGLE_MEET]: {
    dataType: [IntegrationDataTypeEnum.VIDEO_CALL],
    name: 'Google Meet',
    icon: 'LogoGoogleMeetGlyph',
    description: 'Video <br /> Analytics, Insights',
  },

  [IntegrationEnum.MICROSOFT]: {
    subProducts: [
      IntegrationEnum.MICROSOFT_CALENDAR,
      IntegrationEnum.MICROSOFT_MAIL,
      IntegrationEnum.MICROSOFT_VIDEO_CALLS,
      IntegrationEnum.MICROSOFT_MESSAGES,
    ],
    name: 'Microsoft 365',
    icon: 'LogoMicrosoftGlyph',
    description: 'Email, Calendar, Chat, Video<br />Analytics, Insights',
  },

  [IntegrationEnum.MICROSOFT_CALENDAR]: {
    dataType: [IntegrationDataTypeEnum.CALENDAR_EVENT],
    name: 'Outlook',
    icon: 'LogoMicrosoftOutlookGlyph',
    description: 'Calendar <br /> Analytics, Insights',
  },

  [IntegrationEnum.MICROSOFT_MAIL]: {
    dataType: [IntegrationDataTypeEnum.MAIL_MESSAGE],
    name: 'Outlook',
    icon: 'LogoMicrosoftOutlookGlyph',
    description: 'Mail <br /> Analytics, Insights',
  },

  [IntegrationEnum.MICROSOFT_VIDEO_CALLS]: {
    dataType: [IntegrationDataTypeEnum.VIDEO_CALL],
    name: 'Teams',
    icon: 'LogoMicrosoftTeamsGlyph',
    description: 'Video <br /> Analytics, Insights',
  },

  [IntegrationEnum.MICROSOFT_MESSAGES]: {
    dataType: [IntegrationDataTypeEnum.CHAT_MESSAGE],
    name: 'Teams',
    icon: 'LogoMicrosoftTeamsGlyph',
    description: 'Chat <br /> Analytics, Insights',
  },

  [IntegrationEnum.SALESFORCE]: {
    dataType: [IntegrationDataTypeEnum.CRM],
    name: 'Salesforce',
    icon: 'LogoSalesforceGlyph',
    description: 'Chat <br /> Analytics, Insights',
  },

  [IntegrationEnum.TWITTER]: {
    name: 'Twitter',
  },
} as Record<IntegrationEnum, IntegrationConfigType>;
