import getIntegrationConfig from '@/packages/integration/helpers/get-integration-config';
import {
  IntegrationsStatusType,
  IntegrationsAvailableDatesType,
} from '@/packages/integration/types/integration.types';
import { defineStore } from 'pinia';
import { IntegrationEnum } from '@/packages/integration/enums/integration.enum';
import { IntegrationDataTypeEnum } from '@/packages/integration/enums/integration-data-type.enum';

export const useIntegrationStore = defineStore('integration', {
  state: () => ({
    status: {} as IntegrationsStatusType,
    isStatusFetched: false,

    availableDates: null as IntegrationsAvailableDatesType | null,
    isAvailableDatesFetched: false,
  }),

  getters: {
    enabledIntegrations(): IntegrationEnum[] {
      const result: IntegrationEnum[] = [];

      Object.entries(this.status)
        .filter(([uid]) => uid !== IntegrationEnum.TWITTER)
        .filter(([_, info]) => info?.isEnabled)
        .forEach(([uid, data]) => {
          const products = data?.products;

          if (products) {
            const enabledIntegrations = Object.entries(products)
              .filter(([, data]) => {
                return data?.isEnabled;
              })
              .map(([uid]) => {
                return uid as IntegrationEnum;
              });

            result.push(...enabledIntegrations);
          } else {
            result.push(uid as IntegrationEnum);
          }
        });

      return result;
    },

    enabledIntegrationDataTypes(): IntegrationDataTypeEnum[] {
      let enabledDataTypes: IntegrationDataTypeEnum[] = [];

      this.enabledIntegrations.forEach(integration => {
        const config = getIntegrationConfig(integration);
        if (Array.isArray(config?.dataType)) {
          enabledDataTypes = [...enabledDataTypes, ...config.dataType];
        }
      });

      return enabledDataTypes;
    },

    noAvailableDates(): boolean {
      return !this.availableDates?.ALL?.min || !this.availableDates?.ALL?.max;
    },
  },

  actions: {
    setStatus(status: IntegrationsStatusType) {
      this.status = status;
      this.isStatusFetched = true;
    },

    setAvailableDates(dates: IntegrationsAvailableDatesType) {
      this.availableDates = dates;
      this.isAvailableDatesFetched = true;
    },
  },
});
