<template>
  <svg fill="none" viewBox="0 0 500 250" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.734 184.798v-33.691L86.997 50.034h30.571v44.921h-17.469L65.472 149.86v1.248h94.833v33.691H22.735Zm77.676 24.956v-35.251l.936-14.662V50.034H141.9v159.72h-41.49ZM248.872 214.12c-14.506-.052-27.036-3.405-37.59-10.06-10.555-6.655-18.691-16.248-24.41-28.778-5.72-12.53-8.553-27.556-8.501-45.077.052-17.573 2.911-32.495 8.579-44.765 5.719-12.27 13.829-21.603 24.332-27.998 10.554-6.395 23.084-9.592 37.59-9.592 14.506 0 27.036 3.223 37.59 9.67 10.555 6.395 18.691 15.728 24.411 27.998 5.719 12.27 8.552 27.166 8.5 44.687 0 17.625-2.859 32.703-8.578 45.233-5.72 12.53-13.856 22.123-24.411 28.778-10.502 6.603-23.006 9.904-37.512 9.904Zm0-35.25c7.487 0 13.622-3.9 18.405-11.699 4.835-7.85 7.227-20.173 7.175-36.966 0-10.97-1.092-19.939-3.275-26.906-2.184-6.967-5.2-12.114-9.047-15.442-3.847-3.379-8.267-5.069-13.258-5.069-7.487 0-13.596 3.796-18.327 11.386-4.731 7.591-7.149 19.602-7.253 36.031-.052 11.178 1.014 20.381 3.197 27.608 2.184 7.175 5.2 12.478 9.047 15.909 3.899 3.432 8.345 5.148 13.336 5.148ZM338.979 184.798v-33.691l64.262-101.073h30.572v44.921h-17.47l-34.627 54.904v1.248h94.834v33.691H338.979Zm77.676 24.956v-35.251l.936-14.662V50.034h40.554v159.72h-41.49Z"
      fill="#E8EFFF"
    />
    <path
      d="M202.056 140.192c.65-.37 7.987-3.458 44.491 13.458a5.932 5.932 0 0 0-.151.212s-37.95 12.703-45.385-2.947c-2.22-4.659-1.584-7.867.981-10.687l.064-.036ZM324.748 146.592c-7.931 6.627-20.368 11.437-32.369 5.041 1.896-1.204 3.855-2.445 5.853-3.728 12.052-7.697 22.667-2.547 26.516-1.313Z"
      fill="#699CFF"
    />
    <path
      d="M338.953 65.085c8.103 67.065-11.153 79.236-13.297 81.085-.62.536-.872.497-1.81.256l-.016.01c-3.972-1.047-13.546-6.229-25.599 1.467-1.997 1.284-3.957 2.524-5.853 3.729-12.294 7.75-22.223 13.212-29.376 10.042-6.067-3.076-11.537-5.739-16.455-8.026-36.504-16.915-43.842-13.828-44.492-13.457.755-.409 7.675-4.222 16.992-10.926.032-.019.064-.037.071-.061 12.454-8.972 29.133-23.11 41.122-41.297 8.162-12.367 16.146-43.719 31.552-49.87 31.661-11.74 45.847 13.406 47.161 27.048Z"
      fill="#A2BDFF"
    />
    <path
      d="M303.946 63.908c.982-2.58.887-5.006-.214-5.42-1.101-.415-2.79 1.34-3.773 3.92-.983 2.579-.887 5.006.214 5.42 1.101.415 2.79-1.34 3.773-3.92ZM324.105 67.2c.697-2.67.338-5.073-.802-5.366-1.14-.294-2.628 1.632-3.325 4.302-.696 2.67-.337 5.073.803 5.367 1.139.293 2.628-1.633 3.324-4.303ZM305.267 73.071a.728.728 0 0 1 .807.413c.245.543 1.156 2.11 2.1 2.177.943.067 2.49-1.263 3.092-1.917a.725.725 0 0 1 1.257.453.717.717 0 0 1-.188.517c-.238.258-2.372 2.517-4.264 2.383-1.974-.14-3.188-2.734-3.32-3.03a.716.716 0 0 1 .516-.996Z"
      fill="#fff"
    />
    <mask
      id="a"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="178"
      y="48"
      width="79"
      height="167"
    >
      <path fill="#C4C4C4" d="M178.085 48.336h77.963v165.809h-77.963z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M248.872 214.119c-14.506-.052-27.036-3.405-37.59-10.06-10.555-6.655-18.691-16.248-24.41-28.778-5.72-12.53-8.553-27.556-8.501-45.077.052-17.573 2.911-32.495 8.579-44.765 5.719-12.27 13.829-21.603 24.332-27.998 10.554-6.395 23.084-9.592 37.59-9.592 14.506 0 27.036 3.223 37.59 9.67 10.555 6.395 18.691 15.728 24.411 27.998 5.719 12.27 8.552 27.166 8.5 44.687 0 17.625-2.859 32.703-8.578 45.233-5.72 12.53-13.856 22.123-24.411 28.778-10.502 6.603-23.006 9.904-37.512 9.904Zm0-35.25c7.487 0 13.622-3.9 18.405-11.699 4.835-7.85 7.227-20.173 7.175-36.966 0-10.97-1.092-19.939-3.275-26.906-2.184-6.967-5.2-12.114-9.047-15.442-3.847-3.379-8.267-5.069-13.258-5.069-7.487 0-13.596 3.796-18.327 11.387-4.731 7.59-7.149 19.601-7.253 36.03-.052 11.178 1.014 20.381 3.197 27.608 2.184 7.175 5.2 12.478 9.047 15.909 3.899 3.432 8.345 5.148 13.336 5.148Z"
        fill="#E8EFFF"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IllustrationNotFound',
});
</script>

<style lang="scss"></style>
