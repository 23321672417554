import { useGoalConfigStore } from '~/packages/goal/store/config.store';
import { GoalConfigAPI } from '~/packages/goal/api/config.api';

export default defineNuxtRouteMiddleware(async to => {
  const store = useGoalConfigStore();

  if (store.isConfigFetched || to.meta.layout !== 'dashboard') {
    return;
  }

  try {
    const { groups } = await GoalConfigAPI.fetchAvailableGoals();
    store.$patch({ availableGoals: groups, isConfigFetched: true });
  } catch (e) {
    throw e;
  }
});
