import { FilterKeyEnum } from '@/packages/filter/enums/filter-key.enum';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { defineStore } from 'pinia';
import { DatesRangeType } from '~/shared/ui/form/date-picker/types/dates-range.type';
import { Cache } from '~/shared/helpers/cache';
import { HeatmapItemType } from '~/shared/ui/chart/heatmap/types/heatmap-item.type';
import { IntegrationEnum } from '@/packages/integration/enums/integration.enum';
import { DatePickerOptionType } from '~/shared/ui/form/date-picker/types/date-picker-option.type';
import { useDatesStore } from '~/shared/stores/dates.store';

export const useProductivityHeatmapStore = defineStore('productivityHeatmap', () => {
  const datesStore = useDatesStore();

  // CACHE
  const cache = ref(new Cache<HeatmapItemType[]>());

  const activeCacheKey = computed(() => {
    return Cache.generateKey({
      d: dates.value,
      g: grouping.value,
      p: integratedProducts.value,
      t: filters.value,
    });
  });

  const hasCachedActive = computed<boolean>(() => {
    return cache.value.hasItem(activeCacheKey.value);
  });

  const getActiveCacheKeyForRow = computed<(rowId: string | number) => string>(() => {
    return rowId =>
      Cache.generateKey({
        d: dates.value,
        g: grouping.value,
        p: integratedProducts.value,
        t: filters.value,
        r: rowId,
      });
  });

  const activeHeatmap = computed<HeatmapItemType[] | undefined>(() => {
    return cache.value.getItem(activeCacheKey.value);
  });

  function setCacheHeatmap(payload: { key: string; data: HeatmapItemType[] }) {
    cache.value.setItem(payload.key, payload.data);
  }

  // DATEPICKER
  const datepicker = computed(() => {
    return {
      selectedOption: datesStore.selectedOption,
    };
  });
  const dates = computed<DatesRangeType | undefined>(() => {
    return datepicker.value.selectedOption?.dates || undefined;
  });
  const datesDisplay = computed<string>(() => {
    return datepicker.value.selectedOption?.title || '';
  });
  function setDatepickerSelectedOption(option: DatePickerOptionType) {
    datesStore.setSelectedOption(option);
  }

  // GROUPING
  const grouping = ref<FilterKeyEnum>();
  function setGrouping(gr: FilterKeyEnum | undefined) {
    grouping.value = gr;
  }

  // FILTERS
  const filters = ref<FilterCriteria>({});
  function setFilters(f: FilterCriteria) {
    filters.value = f;
  }

  // PRODUCTS
  const integratedProducts = ref<IntegrationEnum[]>([]);
  function setIntegratedProducts(products: IntegrationEnum[]) {
    integratedProducts.value = products;
  }

  return {
    cache,
    activeCacheKey,
    hasCachedActive,
    getActiveCacheKeyForRow,
    activeHeatmap,
    setCacheHeatmap,

    datepicker,
    dates,
    datesDisplay,
    setDatepickerSelectedOption,

    grouping,
    setGrouping,

    filters,
    setFilters,

    integratedProducts,
    setIntegratedProducts,
  };
});
