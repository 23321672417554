import { AppService } from '~/app/app.service';
import { Faker } from '@/packages/fake-data/faker';
import { SurveyFakeData } from '~/packages/attrition/fake-data/survey.fake-data';
import { SurveyStatusEnum } from '@/packages/attrition/enums/survey-status.enum';
import { AlertTeamType } from '@/packages/attrition/types/alert-team.type';
import { AlertType } from '@/packages/attrition/types/alert.type';
import { SurveyKeyEnum } from '@/packages/attrition/enums/survey-key.enum';
import { SurveyType } from '@/packages/attrition/types/survey-response/survey.type';
import { TeamMemberType } from '@/packages/attrition/types/team-member.type';

export class SurveyService extends AppService {
  public sendSurveyToManagers(options: { alertId: string; teamId?: string }) {
    if (Faker.isEnabled()) {
      return;
    }

    const { alertId, teamId } = options;

    return this.api.core
      .post('attrition/survey/manager', { alertId, teamId })
      .then((response: any) => response.data.payload);
  }

  public sendSurveyReminderToManagers(options: { alertId: string; teamId?: string }) {
    if (Faker.isEnabled()) {
      return;
    }

    const { alertId, teamId } = options;

    return this.api.core
      .post('attrition/survey/manager/reminder', { alertId, teamId })
      .then((response: any) => response.data.payload);
  }

  public sendInitialSurvey(options: { alertId: string; teamId?: string; userId?: string }) {
    if (Faker.isEnabled()) {
      return;
    }

    const { alertId, teamId, userId } = options;

    return this.api.core
      .post('attrition/survey/employee/first', {
        alertId,
        teamId,
        userId,
      })
      .then((response: any) => response.data.payload);
  }

  public sendInitialSurveyReminder(options: { alertId: string; teamId?: string; userId?: string }) {
    if (Faker.isEnabled()) {
      return;
    }

    const { alertId, teamId, userId } = options;

    return this.api.core
      .post('attrition/survey/employee/first/reminder', {
        alertId,
        teamId,
        userId,
      })
      .then((response: any) => response.data.payload);
  }

  public sendFinalSurvey(options: { alertId: string; teamId?: string; userId?: string }) {
    if (Faker.isEnabled()) {
      return;
    }

    const { alertId, teamId, userId } = options;

    return this.api.core
      .post('attrition/survey/employee/second', {
        alertId,
        teamId,
        userId,
      })
      .then((response: any) => response.data.payload);
  }

  public sendFinalSurveyReminder(options: { alertId: string; teamId?: string; userId?: string }) {
    if (Faker.isEnabled()) {
      return;
    }

    const { alertId, teamId, userId } = options;

    return this.api.core
      .post('attrition/survey/employee/second/reminder', {
        alertId,
        teamId,
        userId,
      })
      .then((response: any) => response.data.payload);
  }

  public fetchSurveyInfo(uuid: string): Promise<SurveyType> {
    if (Faker.isEnabled()) {
      return SurveyFakeData.getInitialSurvey();
    }

    return this.api.core
      .get(`/attrition/survey/${uuid}/info`)
      .then((response: any) => response.data.payload);
  }

  public fetchSurvey(uuid: string) {
    if (Faker.isEnabled()) {
      return SurveyFakeData.getInitialSurvey().then(survey => ({
        survey,
      }));
    }

    return this.api.core
      .get(`/attrition/survey/${uuid}`)
      .then((response: any) => response.data.payload);
  }

  public isSurveyCompleted(surveyStatusUid: SurveyStatusEnum) {
    return surveyStatusUid === SurveyStatusEnum.COMPLETED;
  }

  public isSurveySent(surveyStatusUid: SurveyStatusEnum) {
    return surveyStatusUid === SurveyStatusEnum.SENT;
  }

  public isSurveyNotSent(surveyStatusUid: SurveyStatusEnum) {
    return surveyStatusUid === SurveyStatusEnum.NOT_SENT;
  }

  public isTeamSurveyResultAvailable(team: AlertTeamType, surveyKey: SurveyKeyEnum) {
    return Object.values(team.members).some(
      member => member[surveyKey]?.status !== SurveyStatusEnum.NOT_SENT
    );
  }

  public getManagerOfTeam(team: AlertTeamType) {
    return Object.values(team.members).find(member => member.isManager);
  }

  public areAllSurveysHasStatus(
    alert: AlertType | null,
    surveyKey: SurveyKeyEnum,
    surveyStatus: SurveyStatusEnum | SurveyStatusEnum[]
  ) {
    return Object.values(alert?.teams || {}).every(team =>
      this.areAllMembersHasStatus(team.members, surveyKey, surveyStatus)
    );
  }

  public areAllMembersHasStatus(
    members: Record<string, TeamMemberType> | TeamMemberType[],
    surveyKey: SurveyKeyEnum,
    surveyStatus: SurveyStatusEnum | SurveyStatusEnum[]
  ) {
    if (!Array.isArray(members)) {
      members = Object.values(members);
    }

    members = members.filter(member =>
      surveyKey === SurveyKeyEnum.PERFORMANCE ? member.isManager : !member.isManager
    );

    return members.every(member => {
      const status = member[surveyKey]?.status;
      if (!status) return false;

      const arr = Array.isArray(surveyStatus) ? surveyStatus : [surveyStatus];
      return arr.includes(status);
    });
  }

  public areSomeSurveysHasStatus(
    alert: AlertType | null,
    surveyKey: SurveyKeyEnum,
    surveyStatus: SurveyStatusEnum | SurveyStatusEnum[]
  ) {
    return Object.values(alert?.teams || {}).some(team =>
      this.areSomeMembersHasStatus(team.members, surveyKey, surveyStatus)
    );
  }

  public areSomeMembersHasStatus(
    members: Record<string, TeamMemberType> | TeamMemberType[],
    surveyKey: SurveyKeyEnum,
    surveyStatus: SurveyStatusEnum | SurveyStatusEnum[]
  ) {
    if (!Array.isArray(members)) {
      members = Object.values(members);
    }

    return members.some(member => {
      const status = member[surveyKey]?.status;
      if (!status) return false;

      const arr = Array.isArray(surveyStatus) ? surveyStatus : [surveyStatus];

      return arr.includes(status);
    });
  }
}
