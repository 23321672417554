import { default as driverspg4W5hiJFBMeta } from "/app/src/pages/attrition/drivers.vue?macro=true";
import { default as heatmappX8HGQ7K5UMeta } from "/app/src/pages/attrition/heatmap.vue?macro=true";
import { default as indexLJAyJClfuaMeta } from "/app/src/pages/attrition/index.vue?macro=true";
import { default as resultsjojicYIfK5Meta } from "/app/src/pages/attrition/results.vue?macro=true";
import { default as indexel2ypCpIBsMeta } from "/app/src/pages/big-bet/[uid]/index.vue?macro=true";
import { default as cost_45driversynpBIGbR0yMeta } from "/app/src/pages/cost-drivers.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as integrationsdkCh6dm78uMeta } from "/app/src/pages/integrations.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as logoutSGKlAvs2ilMeta } from "/app/src/pages/logout.vue?macro=true";
import { default as alert_45result8nyuTe3WnYMeta } from "/app/src/pages/pdf/alert-result.vue?macro=true";
import { default as driversdzY6sHrnEnMeta } from "/app/src/pages/pdf/drivers.vue?macro=true";
import { default as heatmaphOsBCBosL2Meta } from "/app/src/pages/pdf/heatmap.vue?macro=true";
import { default as resultsM66OLH6Bs7Meta } from "/app/src/pages/pdf/results.vue?macro=true";
import { default as team_45resulttlVY4e9twBMeta } from "/app/src/pages/pdf/team-result.vue?macro=true";
import { default as peopleAgnXIOzRj7Meta } from "/app/src/pages/people.vue?macro=true";
import { default as heatmapAlpvMyfBahMeta } from "/app/src/pages/productivity/heatmap.vue?macro=true";
import { default as trendscgSgBqgomDMeta } from "/app/src/pages/productivity/trends.vue?macro=true";
import { default as revenue_45driverslwRNT01bXxMeta } from "/app/src/pages/revenue-drivers.vue?macro=true";
import { default as revenue_45risksbyvcLkhrrsMeta } from "/app/src/pages/revenue-risks.vue?macro=true";
import { default as segmentsXFLSX6rL5aMeta } from "/app/src/pages/segments.vue?macro=true";
import { default as indexu0UTCGKQMpMeta } from "/app/src/pages/survey/a/[uuid]/index.vue?macro=true";
import { default as startPlirGA2mPNMeta } from "/app/src/pages/survey/a/[uuid]/start.vue?macro=true";
export default [
  {
    name: "attrition-drivers",
    path: "/attrition/drivers",
    meta: driverspg4W5hiJFBMeta || {},
    component: () => import("/app/src/pages/attrition/drivers.vue").then(m => m.default || m)
  },
  {
    name: "attrition-heatmap",
    path: "/attrition/heatmap",
    meta: heatmappX8HGQ7K5UMeta || {},
    component: () => import("/app/src/pages/attrition/heatmap.vue").then(m => m.default || m)
  },
  {
    name: "attrition",
    path: "/attrition",
    meta: indexLJAyJClfuaMeta || {},
    component: () => import("/app/src/pages/attrition/index.vue").then(m => m.default || m)
  },
  {
    name: "AttritionResultsRoute",
    path: "/attrition/results",
    meta: resultsjojicYIfK5Meta || {},
    component: () => import("/app/src/pages/attrition/results.vue").then(m => m.default || m)
  },
  {
    name: "big-bet-uid",
    path: "/big-bet/:uid()",
    meta: indexel2ypCpIBsMeta || {},
    component: () => import("/app/src/pages/big-bet/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "cost-drivers",
    path: "/cost-drivers",
    meta: cost_45driversynpBIGbR0yMeta || {},
    component: () => import("/app/src/pages/cost-drivers.vue").then(m => m.default || m)
  },
  {
    name: "IndexPage",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "AnalyticsIntegrations",
    path: "/integrations",
    meta: integrationsdkCh6dm78uMeta || {},
    component: () => import("/app/src/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutSGKlAvs2ilMeta || {},
    component: () => import("/app/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "pdf-alert-result",
    path: "/pdf/alert-result",
    component: () => import("/app/src/pages/pdf/alert-result.vue").then(m => m.default || m)
  },
  {
    name: "pdf-drivers",
    path: "/pdf/drivers",
    component: () => import("/app/src/pages/pdf/drivers.vue").then(m => m.default || m)
  },
  {
    name: "pdf-heatmap",
    path: "/pdf/heatmap",
    component: () => import("/app/src/pages/pdf/heatmap.vue").then(m => m.default || m)
  },
  {
    name: "pdf-results",
    path: "/pdf/results",
    component: () => import("/app/src/pages/pdf/results.vue").then(m => m.default || m)
  },
  {
    name: "pdf-team-result",
    path: "/pdf/team-result",
    component: () => import("/app/src/pages/pdf/team-result.vue").then(m => m.default || m)
  },
  {
    name: "people",
    path: "/people",
    meta: peopleAgnXIOzRj7Meta || {},
    component: () => import("/app/src/pages/people.vue").then(m => m.default || m)
  },
  {
    name: "productivity-heatmap",
    path: "/productivity/heatmap",
    meta: heatmapAlpvMyfBahMeta || {},
    component: () => import("/app/src/pages/productivity/heatmap.vue").then(m => m.default || m)
  },
  {
    name: "productivity-trends",
    path: "/productivity/trends",
    meta: trendscgSgBqgomDMeta || {},
    component: () => import("/app/src/pages/productivity/trends.vue").then(m => m.default || m)
  },
  {
    name: "revenue-drivers",
    path: "/revenue-drivers",
    meta: revenue_45driverslwRNT01bXxMeta || {},
    component: () => import("/app/src/pages/revenue-drivers.vue").then(m => m.default || m)
  },
  {
    name: "revenue-risks",
    path: "/revenue-risks",
    meta: revenue_45risksbyvcLkhrrsMeta || {},
    component: () => import("/app/src/pages/revenue-risks.vue").then(m => m.default || m)
  },
  {
    name: "segments",
    path: "/segments",
    meta: segmentsXFLSX6rL5aMeta || {},
    component: () => import("/app/src/pages/segments.vue").then(m => m.default || m)
  },
  {
    name: "AttritionSurveyForm",
    path: "/survey/a/:uuid()",
    meta: indexu0UTCGKQMpMeta || {},
    component: () => import("/app/src/pages/survey/a/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: "AttritionSurveyStart",
    path: "/survey/a/:uuid()/start",
    meta: startPlirGA2mPNMeta || {},
    component: () => import("/app/src/pages/survey/a/[uuid]/start.vue").then(m => m.default || m)
  }
]