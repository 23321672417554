import { useEmployeeSegmentationStore } from './store/employees-segmentation.store';
import { AppFacade } from '~/app/app.facade';
import { EmployeesSegmentationApi } from '~/packages/employees/api/employees-segmentation.api';

export class EmployeesFacade extends AppFacade {
  readonly segmentationAPI = new EmployeesSegmentationApi();
  readonly segmentationStore;

  constructor() {
    super();
    this.segmentationStore = useEmployeeSegmentationStore();
  }
}
