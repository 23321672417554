import { getErrorReporter } from '~/packages/error-reporter/error-reporter';
import { useSessionStore } from '@/packages/user/store/session.store';
import { AppFacade } from '~/app/app.facade';
import { UserSessionService } from '@/packages/user/service/user-session.service';
import { UserLocaleService } from '@/packages/user/service/user-locale.service';

export class UserFacade extends AppFacade {
  sessionService = new UserSessionService();
  localeService = new UserLocaleService();

  get session() {
    return useSessionStore();
  }

  async fetchAndStoreCurrentUser() {
    const user = await this.sessionService.fetchCurrentUser();
    this.session.setUser({ user });

    if (user) {
      getErrorReporter().setUser({ id: user.id.toString() });
    }
  }

  async fetchAndStoreCurrentOrganisation() {
    const organisation = await this.sessionService.fetchCurrentUserOrganisation();
    this.session.setOrganisation(organisation);
  }

  get now(): Date {
    return this.localeService.date.value;
  }
}
