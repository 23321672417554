import { defineStore } from 'pinia';

export const useWindowStore = defineStore('window-store', () => {
  const scrollY = ref(0);
  const height = ref(window.innerHeight);

  return {
    scrollY,
    height,
  };
});
