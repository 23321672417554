import css from '~/shared/helpers/css';
import * as text from '~/shared/helpers/text';
import * as date from '~/shared/helpers/date';

type HelpersFacade = {
  text: typeof text;
  date: typeof date;
  css: typeof css;
};

export default defineNuxtPlugin(() => {
  const helpers: HelpersFacade = {
    text,
    date,
    css,
  };

  return {
    provide: {
      helpers,
    },
  };
});
