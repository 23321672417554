import { defineStore } from 'pinia';

export const useMouseStore = defineStore('mouse-store', () => {
  const y = ref(0);
  const x = ref(0);

  return {
    y,
    x,
  };
});
