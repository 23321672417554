import dayjs from 'dayjs';
import getStringWithDayOfWeek from './get-string-with-day-of-week';

export default function formatRange(from, to, { alwaysShowYear = true } = {}) {
  const dateFrom = getStringWithDayOfWeek(from);
  const dateTo = getStringWithDayOfWeek(to);

  if (dateFrom === dateTo) {
    return dateFrom;
  }

  const fromObject = dayjs(from);
  const toObject = dayjs(to);

  if (
    fromObject.isSame(to, 'month') &&
    fromObject.startOf('month').isSame(from, 'day') &&
    fromObject.endOf('month').isSame(to, 'day')
  ) {
    return dayjs(from).format('MMMM YYYY');
  }

  const formats = {
    from: 'D',
    to: 'D MMM',
  };

  if (alwaysShowYear) {
    formats.to += ' YYYY';
  }

  const differenceInYear = fromObject.year() !== toObject.year();

  const differenceInMonth = differenceInYear || fromObject.month() !== toObject.month();

  if (differenceInMonth) {
    formats.from += ' MMM';
  }

  if (differenceInYear) {
    formats.from += ' YYYY';
  }

  return fromObject.format(formats.from) + ' – ' + toObject.format(formats.to);
}
