import { defineStore } from 'pinia';

import deepMerge, { Options as deepMergeOptions } from 'deepmerge';
import { AlertType } from '@/packages/attrition/types/alert.type';
import { AlertTeamType } from '@/packages/attrition/types/alert-team.type';
import { ConclusionType } from '@/packages/attrition/types/conclusion.type';
import { SurveysResultType } from '@/packages/attrition/types/survey-result.type';
import { HeatmapType } from '~/shared/ui/chart/heatmap/types/heatmap.type';

import { SurveyKeyEnum } from '@/packages/attrition/enums/survey-key.enum';
import { SurveyStatusEnum } from '@/packages/attrition/enums/survey-status.enum';
import { SuggestionStatusEnum } from '@/packages/attrition/enums/suggestion-status.enum';
import { RecommendationTabEnum } from '@/packages/attrition/enums/recommendation-tab.enum';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { AlertTaskTypeEnum } from '@/packages/attrition/enums/alert-task-type.enum';
import { Cache } from '~/shared/helpers/cache';
import { DriversDetailsType } from '@/packages/attrition/types/drivers-details.type';
import { AlertStatusService } from '@/packages/attrition/services/alert-status.service';
import { Faker } from '@/packages/fake-data/faker';

const overwriteMerge = (
  _destinationArray: any[],
  sourceArray: any[],
  _options: deepMerge.ArrayMergeOptions
) => sourceArray;

export const useAttritionAlertStore = defineStore('attritionAlert', {
  state: () => ({
    alerts: {} as Record<string, AlertType>,
    activeTabUid: RecommendationTabEnum.RECOMMENDATION,
    activeAlertId: '',

    driversFilters: {} as Record<string, FilterCriteria>,
    driversDetailsCache: new Cache<DriversDetailsType>(),

    openedTaskModal: null as AlertTaskTypeEnum | null,
  }),

  getters: {
    activeAlert(): AlertType | null {
      return this.alerts[this.activeAlertId] || null;
    },

    activeDriversDetailsCacheKey(): string {
      return Cache.generateKey({
        i: this.activeAlertId,
        f: this.driversFilters[this.activeAlertId] || null,
      });
    },

    hasCachedActiveDriversDetails(): boolean {
      return this.driversDetailsCache.hasItem(this.activeDriversDetailsCacheKey);
    },

    activeDriversDetails(): DriversDetailsType | undefined {
      return this.driversDetailsCache.getItem(this.activeDriversDetailsCacheKey);
    },
  },

  actions: {
    openTaskModal(taskUid: AlertTaskTypeEnum) {
      this.openedTaskModal = taskUid;
    },

    closeTaskModal() {
      this.openedTaskModal = null;
    },

    setAlerts(alerts: AlertType[]) {
      this.alerts = alerts.reduce((acc: Record<string, AlertType>, alert) => {
        acc[alert.id] = { ...alert };

        return acc;
      }, {});
    },

    setActiveAlertId(alertId: string) {
      this.activeAlertId = alertId;
    },

    setAlertTeams(payload: { alertId: string; teams: Record<string, AlertTeamType> }) {
      const { alertId, teams } = payload;

      this.alerts = {
        ...this.alerts,
        [alertId]: { ...this.alerts[alertId], teams },
      };
    },

    setDriversFilters(payload: { alertId: string; filters: FilterCriteria }) {
      this.driversFilters[payload.alertId] = payload.filters;
    },

    setAlertHeatmap(payload: { alertId: string; heatmap: HeatmapType }) {
      const { alertId, heatmap } = payload;

      if (!this.alerts[alertId]) {
        return;
      }

      this.alerts[alertId] = {
        ...this.alerts[alertId],
        heatmap,
      };
    },

    updateAlertHeatmap(payload: { alertId: string; updateHeatmap: Partial<HeatmapType> }) {
      const { alertId, updateHeatmap } = payload;

      if (!this.alerts[alertId]) {
        return;
      }

      this.alerts[alertId] = {
        ...this.alerts[alertId],
        heatmap: deepMerge(this.alerts[alertId].heatmap as HeatmapType, updateHeatmap, {
          arrayMerge: overwriteMerge,
        }),
      };
    },

    setAlertDrivers(payload: { alertId: string; drivers: any }) {
      const { alertId, drivers } = payload;

      this.alerts[alertId].drivers = drivers;
    },

    setAlertResults(payload: { alertId: string; results: ConclusionType }) {
      const { alertId, results } = payload;

      this.alerts[alertId].results = results;
    },

    setActiveTabUid(uid: RecommendationTabEnum) {
      this.activeTabUid = uid;
    },

    setAlertTeamResults(payload: ISetAlertTeamResult) {
      const { alertId, teamId, results } = payload;

      const newTeams = { ...this.alerts[alertId].teams };

      if (!newTeams) {
        return;
      }

      newTeams[teamId] = {
        ...newTeams[teamId],
        results,
      };

      this.setAlertTeams({ alertId, teams: newTeams });
    },

    setMemberSurveyStatus(payload: ISetMemberSurveyStatus) {
      const { alertId, teamId, memberId, status, surveyKey } = payload;

      const memberSurvey = this.alerts[alertId].teams?.[teamId].members[memberId][surveyKey];

      if (memberSurvey) {
        memberSurvey.status = status;
      }
    },

    setManagerSuggestionStatus(payload: ISetManagerSuggestionStatus) {
      const { alertId, teamId, memberId, status } = payload;

      this.updateAlert({
        alertId,
        alertData: {
          teams: {
            [teamId]: {
              members: {
                [memberId]: {
                  suggestion: {
                    status,
                  },
                },
              },
            },
          },
        } as Partial<AlertType>,
      });
    },

    setTeamSurveyStatus(payload: ISetTeamSurveyStatus) {
      const { alertId, teamId, status, surveyKey } = payload;

      const members = this.alerts[alertId].teams?.[teamId].members;

      if (!members) {
        return;
      }

      Object.keys(members).forEach(memberId => {
        this.setMemberSurveyStatus({
          alertId,
          teamId,
          memberId,
          surveyKey,
          status,
        });
      });
    },

    setAlertTaskStatus(payload: ISetAlertTaskStatus) {
      const { alertId, status, surveyKey } = payload;

      this.updateAlert({
        alertId,
        alertData: {
          [surveyKey]: { status },
        },
      });
    },

    setAlertSuggestionStatus(payload: { alertId: string; status: SuggestionStatusEnum }) {
      const { alertId, status } = payload;

      this.updateAlert({
        alertId,
        alertData: {
          suggestionsForManagers: { status },
        } as Partial<AlertType>,
      });
    },

    removeExpandedRow(payload: { alertId: string; expandedRowId: string | number }) {
      const alert = this.alerts[payload.alertId];
      const expandedRows = alert?.heatmap?.expandedRows;

      if (!expandedRows) {
        return;
      }

      delete expandedRows[payload.expandedRowId];
    },

    updateAlert(payload: {
      alertId: string;
      alertData: Partial<AlertType>;
      options?: deepMergeOptions;
    }) {
      const {
        alertId,
        alertData,
        options = {
          arrayMerge: overwriteMerge,
        },
      } = payload;

      if (!this.alerts[alertId]) {
        return;
      }

      this.alerts[alertId] = deepMerge(this.alerts[alertId], alertData, options);
      this.alerts[alertId].status = AlertStatusService.getStatus(this.alerts[alertId]);
    },

    cacheDriversDetails(payload: { key: string; data: DriversDetailsType }) {
      this.driversDetailsCache.setItem(payload.key, payload.data);
    },

    async fetchAlertActionAndUpdate(promise: Promise<AlertType> | undefined): Promise<any> {
      if (Faker.isEnabled() || !promise) {
        return;
      }

      try {
        const newAlert = await promise;

        this.updateAlert({ alertId: newAlert.id, alertData: newAlert });
      } catch (e) {
        console.error(e);
      }
    },
  },
});

interface ISetMemberSurveyStatus {
  alertId: string;
  teamId: string;
  memberId: string;
  surveyKey: SurveyKeyEnum;
  status: SurveyStatusEnum;
}

interface ISetManagerSuggestionStatus {
  alertId: string;
  teamId: string;
  memberId: string;
  status: SuggestionStatusEnum;
}

interface ISetTeamSurveyStatus {
  alertId: string;
  teamId: string;
  surveyKey: SurveyKeyEnum;
  status: SurveyStatusEnum;
}

interface ISetAlertTaskStatus {
  alertId: string;
  surveyKey: SurveyKeyEnum;
  status: SurveyStatusEnum;
}

interface ISetAlertTeamResult {
  alertId: string;
  teamId: string;
  results: SurveysResultType;
}
