import { FilterOptionType } from '@/packages/filter/types/filter-option.type';

export function convertFilterOptionsToObject(
  filters: FilterOptionType[]
): Record<string, FilterOptionType> {
  return filters.reduce(
    (obj, option) => ({
      ...obj,
      [option.uid]: option,
    }),
    {}
  );
}
