import { SurveyInventoryType } from '@/packages/attrition/types/survey-inventory.type';

const initialSurveyInventory: SurveyInventoryType = {
  public: {
    headerUnderTitle: 'Anonymous survey',
    headerTitle: 'Let us know how you are feeling',
    description({ organisationName }: { organisationName: string }) {
      return `Fill out this anonymous survey to let ${organisationName} know how you are feeling and whether you intend to leave the company in the nearest future`;
    },
    privacy:
      'This is an anonymous survey. We will never reveal your name or personal data to anyone at your company. Your responses will be aggregated into meta-data and shown as anonymous feedback. The main goal is to improve how the company is run.',

    startTitle: 'Anonymous survey',
    startDescription({ organisationName }: { organisationName: string }) {
      return `Please, fill in this anonymous survey to help ${organisationName} better serve your needs`;
    },
  },

  admin: {
    questions: ({ companyName }) => [
      {
        id: '1',
        index: '1',
        title: `I feel valued at ${companyName}`,
        isAdditional: false,
        answersType: '7 point scale',
        answersSummary: 'Aligning expectations',
        answersSummaryHint:
          'This question is intended to understand whether top performers feel undervalued',
      },
      {
        id: '2',
        index: '2',
        title: `I am likely to stay at ${companyName} for`,
        isAdditional: false,
        answersType: 'Multiple choice',
        answersSummary: 'Confirming intent to leave',
        answersSummaryHint:
          'This question is intended to understand whether top performers intent to leave',
      },
      {
        id: '3',
        index: '2a',
        title: `If you were to leave ${companyName} for another job, the main reason would be`,
        isAdditional: true,
        dependsOnQuestionId: 2,
        answersType: 'Multiple choice',
        answersSummary: 'Reason for intent to leave',
        answersSummaryHint:
          'This question is intended to understand the true reason behind the previously identified intent to leave',
      },
      {
        id: '4',
        index: '2b',
        title: 'Please, explain',
        isAdditional: true,
        dependsOnQuestionId: 2,
        answersType: 'free text',
        answersSummary: 'Reason(s) explained',
        answersSummaryHint:
          'This question is intended to understand the specifics behind the previously identified intent to leave',
      },
    ],
  },
};

export default initialSurveyInventory;
