import { SurveyInventoryType } from '@/packages/attrition/types/survey-inventory.type';

const performanceSurveyInventory: SurveyInventoryType = {
  public: {
    headerUnderTitle: 'Manager survey',
    headerTitle: 'Who do you identify as top performers on your team',
    description({ organisationName }: { organisationName: string }) {
      return `Fill out this survey to let ${organisationName} know who you identify as top performers at this moment in time.<br/>Based on your responses, Performetry will provide you with the likelihood of top performers leaving in the nearest future`;
    },
    privacy:
      'The main goal is to provide you with information on the likelihood of top performers leaving your team in the nearest future',

    startTitle: 'Manager survey',
    startDescription({ organisationName }: { organisationName: string }) {
      return `Please, fill in this manager survey to help ${organisationName} better serve the needs of top performers`;
    },
  },

  admin: {
    questions: ({ companyName: _c }) => [
      {
        id: '8',
        index: '1',
        title: 'If {{employee name}} got a job offer elsewhere, I would…',
        isAdditional: false,
        dependsOnQuestionId: 1,
        answersType: '5 point scale',
        answersSummary: 'Top performer rating',
        answersSummaryHint:
          'This questions is intended to understand how do you rate your team in terms of individual performance as of today',
      },
    ],
  },
};

export default performanceSurveyInventory;
