export enum FilterKeyEnum {
  QUIET_QUITTERS = 'QUIET_QUITTERS',
  USER = 'USER',
  TEAM_MANAGER = 'TEAM_MANAGER',
  DIVISION = 'DIVISION',
  DEPARTMENT = 'DEPARTMENT',
  POSITION = 'POSITION',
  COUNTRY = 'COUNTRY',
  CITY = 'CITY',
  GENERATION = 'GENERATION',
  DEI = 'DEI',
  GENDER = 'GENDER',
  TENURE = 'TENURE',
  PROMOTION = 'PROMOTION',
  PAY_INCREASE = 'PAY_INCREASE',
  PERFORMANCE = 'PERFORMANCE',
}
