<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="90" cy="90" r="70" fill="#E8EFFF" />
    <path
      d="M133.154 77.2537c1.071.3979 2.242.4244 3.355.1688 4.665-1.0708 20.706-3.906 17.919 8.5243-.607 2.4663-10.002 16.2782 3.377 21.3192a9.202 9.202 0 0 0 3.067.604c4.119.085 12.282-.887 15.699-4.365 4.127-4.2015-.382-2.209-.382-2.209s-15.69 6.847-21.232-.417c-.617-.809-1.01-1.9826-1.122-2.9932-.195-1.7521.583-4.0676 1.605-6.0832 1.383-2.7281 8.024-14.9998-6.395-18.5748-3.321-.791-8.145-1.5015-14.366 1.1175-3.628 1.5275-2.824 2.4254-1.525 2.9084Z"
      fill="#BDD6F9"
    />
    <path
      d="m136.366 72.6725-4.177 1.563c-1.441.5395-2.173 2.1456-1.633 3.5872l.207.5531c.54 1.4416 2.146 2.1729 3.587 1.6334l4.176-1.563c1.442-.5396 2.173-2.1456 1.634-3.5873l-.207-.5531c-.54-1.4416-2.146-2.1729-3.587-1.6333Z"
      fill="#2F80ED"
    />
    <path
      d="m110.73 77.1952-13.7725 5.1547c-.7208.2698-1.0865 1.0728-.8167 1.7936l.0543.145c.2697.7208 1.0728 1.0864 1.7936.8167l13.7733-5.1547c.72-.2698 1.086-1.0728.816-1.7936l-.054-.145c-.27-.7208-1.073-1.0864-1.794-.8167ZM114.574 87.4655l-13.773 5.1547c-.721.2697-1.0862 1.0728-.8164 1.7936l.0544.145c.27.7208 1.073 1.0864 1.793.8166l13.773-5.1547c.721-.2698 1.087-1.0728.817-1.7936l-.054-.145c-.27-.7208-1.073-1.0864-1.794-.8166Z"
      fill="#fff"
    />
    <path
      d="m127.291 70.1861-11.163 4.1778c-1.442.5395-2.173 2.1456-1.633 3.5872l3.811 10.183c.539 1.4416 2.145 2.1729 3.587 1.6334l11.163-4.1778c1.441-.5395 2.172-2.1456 1.633-3.5872l-3.811-10.183c-.54-1.4416-2.146-2.1729-3.587-1.6334Z"
      fill="#A2BDFF"
    />
    <path
      d="m117.281 72.8575-11.163 4.1778c-1.442.5395-2.173 2.1456-1.633 3.5872l4.547 12.1487c.539 1.4417 2.145 2.1729 3.587 1.6334l11.162-4.1778c1.442-.5395 2.173-2.1456 1.634-3.5872l-4.547-12.1487c-.54-1.4417-2.146-2.1729-3.587-1.6334Z"
      fill="#699CFF"
    />
    <path
      d="m119.028 70.3921-.088.0328c-.961.3597-1.448 1.4304-1.089 2.3915l6.37 17.019c.36.9611 1.43 1.4486 2.392 1.0889l.087-.0327c.961-.3597 1.449-1.4304 1.089-2.3915l-6.37-17.0191c-.359-.9611-1.43-1.4486-2.391-1.0889Z"
      fill="#2F80ED"
    />
    <path
      d="M5.2057 81.2735s22.4185-6.3002 19.1637 8.2148c-.5653 2.2967-8.7017 14.1227.99 19.7997 2.0811 1.22 4.4827 1.76 6.8944 1.747 4.324-.022 11.1789-.855 14.2584-3.989 4.1272-4.201-.3817-2.209-.3817-2.209s-12.8752 5.296-19.4991 1.074c-2.2618-1.441-3.2869-4.232-2.6728-6.8431a15.6967 15.6967 0 0 1 1.4226-3.7243c1.4406-2.6981 8.0243-14.9998-6.3944-18.5748-3.3215-.7909-8.1457-1.5015-14.3661 1.1175-6.2204 2.6189.585 3.3872.585 3.3872Z"
      fill="#BDD6F9"
    />
    <path
      d="m49.5267 101.589-4.1762 1.563c-1.4416.539-2.1729 2.145-1.6333 3.587l.207.553c.5395 1.442 2.1456 2.173 3.5872 1.633l4.1762-1.563c1.4416-.539 2.1729-2.145 1.6334-3.587l-.2071-.553c-.5395-1.442-2.1456-2.173-3.5872-1.633Z"
      fill="#2F80ED"
    />
    <path
      d="m56.5819 94.7178-6.1894 2.3165c-1.4416.5395-2.1729 2.1456-1.6333 3.5877l2.9844 7.974c.5396 1.441 2.1456 2.173 3.5873 1.633l6.1893-2.316c1.4416-.54 2.1729-2.146 1.6333-3.588l-2.9844-7.9738c-.5396-1.4416-2.1456-2.1729-3.5872-1.6334Z"
      fill="#A2BDFF"
    />
    <path
      d="m66.8863 88.5783-9.2301 3.4545c-1.4416.5396-2.1729 2.1456-1.6334 3.5873l4.5468 12.1489c.5396 1.441 2.1456 2.173 3.5873 1.633l9.23-3.454c1.4417-.54 2.1729-2.146 1.6334-3.588l-4.5468-12.1483c-.5396-1.4416-2.1456-2.1729-3.5872-1.6334Z"
      fill="#699CFF"
    />
    <path
      d="m68.8414 86.0625-.0875.0328c-.9611.3597-1.4486 1.4304-1.0889 2.3915l6.3696 17.0192c.3597.961 1.4304 1.448 2.3915 1.089l.0875-.033c.9611-.36 1.4486-1.43 1.0889-2.392l-6.3696-17.0186c-.3597-.961-1.4304-1.4486-2.3915-1.0889ZM92.4116 75.22a1.291 1.291 0 0 1-.8511-.4587 1.291 1.291 0 0 1-.2942-.921l.6887-12.5792c.041-.7514.6756-1.3223 1.3957-1.2558l1.0033.0929c.7201.0664 1.2357.7432 1.1349 1.4893l-1.6923 12.4863a1.3006 1.3006 0 0 1-1.385 1.1462ZM86.4645 76.3047a.822.822 0 0 1-.6244-.0671.8196.8196 0 0 1-.3941-.489l-2.4593-7.8249a.8825.8825 0 0 1 .5684-1.1097l.6367-.1922a.8225.8225 0 0 1 .6557.0853.822.822 0 0 1 .3816.54l1.8228 8.0171a.8811.8811 0 0 1-.5874 1.0405ZM83.1461 80.8169c-.4406.546-1.3269.5395-2.0091-.0148l-12.1118-9.8405c-.7235-.5878-.937-1.5608-.4695-2.1396l.6516-.8072c.4675-.579 1.4276-.5316 2.1122.1045l11.4604 10.6478c.6454.5996.8068 1.5038.3662 2.0498ZM88.7441 106.234c.312.12.565.357.7058.661.1409.303.1585.649.0493.965l-3.8785 11.986c-.2317.716-.9909 1.105-1.6702.858l-.9468-.346c-.6792-.248-1.0052-1.035-.7169-1.73l4.8251-11.64c.1225-.312.3606-.564.6647-.705.3041-.14.6507-.158.9675-.049ZM94.7705 106.704a.8106.8106 0 0 1 .3156.054.8125.8125 0 0 1 .271.17.8215.8215 0 0 1 .1851.261c.044.099.0682.205.0711.313l.3794 8.193a.8818.8818 0 0 1-.2246.636.8837.8837 0 0 1-.6084.292l-.6648.023a.8224.8224 0 0 1-.3323-.061.818.818 0 0 1-.2799-.189.82.82 0 0 1-.231-.619l.2855-8.217a.8824.8824 0 0 1 .2493-.591.8798.8798 0 0 1 .584-.265ZM99.1312 103.189c.5656-.416 1.4208-.183 1.9388.527l9.197 12.607c.549.753.507 1.748-.093 2.189l-.836.614c-.6.44-1.516.149-2.016-.641l-8.3609-13.221c-.4701-.745-.3954-1.66.1701-2.075Z"
      fill="#2F80ED"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IllustrationNetworkError',
});
</script>

<style lang="scss"></style>
