export function generateMidpointDisplacement(length: number, roughness: number): number[] {
  const array = new Array(length).fill(0);
  array[0] = Math.random();
  array[length - 1] = Math.random();

  function midpointDisplacement(start: number, end: number, range: number) {
    if (end - start < 2) {
      return;
    }

    const midpoint = Math.floor((start + end) / 2);
    array[midpoint] = (array[start] + array[end]) / 2 + (Math.random() - 0.5) * range;

    midpointDisplacement(start, midpoint, range * roughness);
    midpointDisplacement(midpoint, end, range * roughness);
  }

  midpointDisplacement(0, length - 1, 1);

  return array;
}
