import { EmployeesFacade } from '~/packages/employees/employees.facade';

export default defineNuxtPlugin(() => {
  const employees = new EmployeesFacade();

  return {
    provide: {
      employees,
    },
  };
});
