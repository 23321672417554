import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_reporter_plugin_PLfj59FWjP from "/app/src/packages/error-reporter/error-reporter.plugin.ts";
import attrition_plugin_8NDdudqhPy from "/app/src/packages/attrition/attrition.plugin.ts";
import productivity_plugin_0Wslvs9NwV from "/app/src/packages/productivity/productivity.plugin.ts";
import survey_plugin_zHnhf96vfm from "/app/src/packages/survey/survey.plugin.ts";
import filter_plugin_8znufmODD8 from "/app/src/packages/filter/filter.plugin.ts";
import pdf_plugin_wrLn3ttXKn from "/app/src/packages/pdf/pdf.plugin.ts";
import employees_plugin_p65MNHAsE2 from "/app/src/packages/employees/employees.plugin.ts";
import user_plugin_Nvh5yeIHWc from "/app/src/packages/user/user.plugin.ts";
import ui_plugin_6f9qBiEVCL from "/app/src/shared/ui/ui.plugin.ts";
import apexchart_plugin_rIhgJuF1JZ from "/app/src/shared/ui/chart/apexchart/apexchart.plugin.ts";
import integration_plugin_qPmJy92J7C from "/app/src/packages/integration/integration.plugin.ts";
import mattermost_plugin_hpCm3iHczM from "/app/src/packages/integration/mattermost/mattermost.plugin.ts";
import slack_plugin_dcy0KW6YfG from "/app/src/packages/integration/slack/slack.plugin.ts";
import dayjs_LvumIHegoG from "/app/src/app/plugins/dayjs.ts";
import directives_LVv8SzF8fF from "/app/src/app/plugins/directives.ts";
import gtm_client_yhDT7xSvgk from "/app/src/app/plugins/gtm.client.ts";
import helpers_3Hm1Gz6h57 from "/app/src/app/plugins/helpers.ts";
import vuetify_nnnwMnsRoB from "/app/src/app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  error_reporter_plugin_PLfj59FWjP,
  attrition_plugin_8NDdudqhPy,
  productivity_plugin_0Wslvs9NwV,
  survey_plugin_zHnhf96vfm,
  filter_plugin_8znufmODD8,
  pdf_plugin_wrLn3ttXKn,
  employees_plugin_p65MNHAsE2,
  user_plugin_Nvh5yeIHWc,
  ui_plugin_6f9qBiEVCL,
  apexchart_plugin_rIhgJuF1JZ,
  integration_plugin_qPmJy92J7C,
  mattermost_plugin_hpCm3iHczM,
  slack_plugin_dcy0KW6YfG,
  dayjs_LvumIHegoG,
  directives_LVv8SzF8fF,
  gtm_client_yhDT7xSvgk,
  helpers_3Hm1Gz6h57,
  vuetify_nnnwMnsRoB
]