import { AppFacade } from '~/app/app.facade';

import { useAttritionDriversStore } from './store/attrition-drivers.store';
import { useAttritionResultsStore } from './store/attrition-results.store';
import { useAttritionHeatmapStore } from './store/attrition-heatmap.store';
import { useAttritionAlertStore } from './store/attrition.store';

import { HeatmapService } from './services/heatmap.service';
import { DriversService } from './services/drivers.service';
import { AlertService } from './services/alert.service';
import { SurveyService } from './services/survey.service';
import { SuggestionService } from './services/suggestion.service';
import { ConclusionService } from './services/conclusion.service';

export class AttritionFacade extends AppFacade {
  private readonly alertService = new AlertService();
  private readonly surveyService = new SurveyService();
  private readonly suggestionService = new SuggestionService();
  private readonly conclusionService = new ConclusionService();
  private readonly heatmapService = new HeatmapService();
  private readonly driversService = new DriversService();

  readonly store;
  readonly heatmapStore;
  readonly resultsStore;
  readonly driversStore;

  constructor() {
    super();

    this.store = useAttritionAlertStore();
    this.heatmapStore = useAttritionHeatmapStore();
    this.resultsStore = useAttritionResultsStore();
    this.driversStore = useAttritionDriversStore();
  }

  public getAlertService(): AlertService {
    return this.alertService;
  }

  public getSurveyService(): SurveyService {
    return this.surveyService;
  }

  public getSuggestionService(): SuggestionService {
    return this.suggestionService;
  }

  public getConclusionService(): ConclusionService {
    return this.conclusionService;
  }

  public getHeatmapService(): HeatmapService {
    return this.heatmapService;
  }

  public getDriversService(): DriversService {
    return this.driversService;
  }
}
