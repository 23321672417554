import { defineStore } from 'pinia';
import { SnackbarMessageType } from '~/shared/ui/snackbar/snackbar.type';
import { SnackbarMessageTypeEnum } from '~/shared/ui/snackbar/snackbar.enum';

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    text: null as string | null,
    type: null as SnackbarMessageTypeEnum | null,
    visible: false,
  }),

  actions: {
    hide() {
      this.visible = false;
    },

    show(message: SnackbarMessageType) {
      this.text = message.text;
      this.type = message.type;
      this.visible = true;
    },
  },
});
