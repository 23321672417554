import { SurveyInventoryType } from '@/packages/attrition/types/survey-inventory.type';

const finalSurveyInventory: SurveyInventoryType = {
  public: {
    headerUnderTitle: 'Anonymous survey',
    headerTitle: 'Let us know if anything has changed',
    description({ organisationName }: { organisationName: string }) {
      return `Fill out this anonymous survey to let ${organisationName} know if anything has changed in terms of your intent to leave`;
    },
    privacy:
      'This is an anonymous survey. We will never reveal your name or personal data to anyone at your company. Your responses will be aggregated into meta-data and shown as anonymous feedback. The main goal is to improve how the company is run.',

    startTitle: 'Anonymous survey 2',
    startDescription({ organisationName }: { organisationName: string }) {
      return `Please, fill in this anonymous survey 2 to help ${organisationName} understand if anything has changed since the last survey`;
    },
  },

  admin: {
    questions: ({ companyName }) => [
      {
        id: '5',
        index: '1',
        title: `You previously identified that you had an intention to stay at ${companyName} for specified period. Has anything changed?`,
        isAdditional: false,
        answersType: '3 point scale',
        answersSummary: 'Re-confirming intent to leave',
        answersSummaryHint:
          'This question is intended to understand whether anything has changed in the previously identified intent to leave',
      },
      {
        id: '6',
        index: '1a',
        title: `I am likely to stay at ${companyName} for`,
        isAdditional: true,
        dependsOnQuestionId: 1,
        answersType: 'Multiple choice',
        answersSummary: 'Confirming intent to stay',
        answersSummaryHint: 'This question is intended to understand the updated intent to leave',
      },
      {
        id: '7',
        index: '1b',
        title: 'Please, explain',
        isAdditional: true,
        dependsOnQuestionId: 1,
        answersType: 'Free text',
        answersSummary: 'Reason(s) explained',
        answersSummaryHint:
          "This question is intended to learn the specifics with regards to the employee's change in intent to leave",
      },
    ],
  },
};

export default finalSurveyInventory;
