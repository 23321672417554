import { AppService } from '~/app/app.service';
import { AlertType } from '@/packages/attrition/types/alert.type';
import { SuggestionStatusEnum } from '@/packages/attrition/enums/suggestion-status.enum';
import { Faker } from '@/packages/fake-data/faker';

export class SuggestionService extends AppService {
  public areAllSuggestionsHasStatus(
    alert: AlertType | null,
    suggestionStatus: SuggestionStatusEnum
  ): boolean {
    if (!alert) {
      return false;
    }
    return Object.values(alert?.teams || {}).every(team => {
      const manager = Object.values(team.members).find(member => member.isManager);

      return manager?.suggestion?.status === suggestionStatus;
    });
  }

  public sendSuggestionToManager(alertId: string, managerId: string) {
    if (Faker.isEnabled()) {
      return;
    }

    return this.api.core
      .get(`/alert/${alertId}/suggestion/send/manager/${managerId}`)
      .then((response: any) => response.data.payload);
  }

  public sendSuggestionToAllManagers(alertId: string) {
    if (Faker.isEnabled()) {
      return;
    }

    return this.api.core
      .get(`/alert/${alertId}/suggestion/send/`)
      .then((response: any) => response.data.payload);
  }

  public sendAllSuggestionsToAllManagers(alertId: string) {
    if (Faker.isEnabled()) {
      return;
    }

    return this.api.core
      .get(`/alert/${alertId}/suggestion/send/`)
      .then((response: any) => response.data.payload);
  }
}
