import dayjs from 'dayjs';
import { HeatmapItemType } from '~/shared/ui/chart/heatmap/types/heatmap-item.type';
import { HeatmapTrendType } from '~/shared/ui/chart/heatmap/types/heatmap-trend.type';
import { HeatmapColumnsEnum } from '~/packages/attrition/enums/heatmap-columns.enum';
import { IHeatmapDto } from '~/shared/ui/chart/heatmap/types/i-heatmap-dto';
import { FilterKeyEnum } from '~/packages/filter/enums/filter-key.enum';

export class HeatmapFakeData {
  public static getHeatmapData(dto: IHeatmapDto): Promise<HeatmapItemType[]> {
    const map = HeatmapFakeData.getHeatmapDataMap();

    if (dto.grouping && dto.grouping in map && !('groupingId' in dto)) {
      const response = map[dto.grouping as FilterKeyEnum] as HeatmapItemType[];
      return Promise.resolve(response);
    }

    return Promise.resolve([]);
  }

  public static getHeatmapCellTrendData(): Promise<HeatmapTrendType> {
    return Promise.resolve({
      [HeatmapColumnsEnum.ENGAGEMENT_DIFFERENCE]: [
        {
          value: -10,
          date: dayjs().subtract(7, 'month').endOf('month').toISOString(),
        },
        {
          value: 3,
          date: dayjs().subtract(6, 'month').endOf('month').toISOString(),
        },
        {
          value: -16,
          date: dayjs().subtract(5, 'month').endOf('month').toISOString(),
        },
        {
          value: 50,
          date: dayjs().subtract(4, 'month').endOf('month').toISOString(),
        },
        {
          value: 34,
          date: dayjs().subtract(3, 'month').endOf('month').toISOString(),
        },
        {
          value: 23,
          date: dayjs().subtract(2, 'month').endOf('month').toISOString(),
        },
        {
          value: -40,
          date: dayjs().subtract(1, 'month').endOf('month').toISOString(),
        },
      ],
      [HeatmapColumnsEnum.PRODUCTIVITY_DIFFERENCE]: [
        {
          value: -10,
          date: dayjs().subtract(7, 'month').endOf('month').toISOString(),
        },
        {
          value: 3,
          date: dayjs().subtract(6, 'month').endOf('month').toISOString(),
        },
        {
          value: -16,
          date: dayjs().subtract(5, 'month').endOf('month').toISOString(),
        },
        {
          value: 50,
          date: dayjs().subtract(4, 'month').endOf('month').toISOString(),
        },
        {
          value: 34,
          date: dayjs().subtract(3, 'month').endOf('month').toISOString(),
        },
        {
          value: 10,
          date: dayjs().subtract(2, 'month').endOf('month').toISOString(),
        },
        {
          value: -40,
          date: dayjs().subtract(1, 'month').endOf('month').toISOString(),
        },
      ],
      [HeatmapColumnsEnum.BURNOUT_DIFFERENCE]: [
        {
          value: -10,
          date: dayjs().subtract(7, 'month').endOf('month').toISOString(),
        },
        {
          value: 3,
          date: dayjs().subtract(6, 'month').endOf('month').toISOString(),
        },
        {
          value: -16,
          date: dayjs().subtract(5, 'month').endOf('month').toISOString(),
        },
        {
          value: 50,
          date: dayjs().subtract(4, 'month').endOf('month').toISOString(),
        },
        {
          value: 34,
          date: dayjs().subtract(3, 'month').endOf('month').toISOString(),
        },
        {
          value: 10,
          date: dayjs().subtract(2, 'month').endOf('month').toISOString(),
        },
        {
          value: -40,
          date: dayjs().subtract(1, 'month').endOf('month').toISOString(),
        },
      ],
    });
  }

  public static getHeatmapDataMap(): Partial<Record<FilterKeyEnum, HeatmapItemType[]>> {
    return {
      [FilterKeyEnum.PERFORMANCE]: [
        {
          id: 0,
          isAffected: false,
          title: 'Top performers',
          employeesCount: 230,
          medianTenure: 4,
          beyondMedianTenure: 151,
          sincePromotion: 2,
          sincePayIncrease: 1,
          riskOfAttrition: 60,
          engagementDifference: -2,
          productivityDifference: -3,
          burnoutDifference: -6,
          retentionSaving: 1253967,
          topPerformersInRisk: 23,
        },
        {
          id: 1,
          isAffected: false,
          title: 'Mid performers',
          employeesCount: 480,
          medianTenure: 2,
          beyondMedianTenure: 87,
          sincePromotion: 1,
          sincePayIncrease: 1,
          riskOfAttrition: 23,
          engagementDifference: -2,
          productivityDifference: -7,
          burnoutDifference: -9,
          retentionSaving: 477767,
          topPerformersInRisk: null,
        },
        {
          id: 2,
          isAffected: false,
          title: 'Bottom performers',
          employeesCount: 122,
          medianTenure: 2,
          beyondMedianTenure: 82,
          sincePromotion: 2,
          sincePayIncrease: 1,
          riskOfAttrition: 13,
          engagementDifference: -2,
          productivityDifference: -5,
          burnoutDifference: 8,
          retentionSaving: 263145,
          topPerformersInRisk: null,
        },
      ],

      [FilterKeyEnum.QUIET_QUITTERS]: [
        {
          id: 0,
          isAffected: false,
          title: 'Quiet quitters',
          employeesCount: 400,
          medianTenure: 4,
          beyondMedianTenure: 151,
          sincePromotion: 2,
          sincePayIncrease: 2,
          riskOfAttrition: 10,
          engagementDifference: -2,
          productivityDifference: -3,
          burnoutDifference: -6,
          retentionSaving: 1253960,
          topPerformersInRisk: 23,
        },
      ],

      [FilterKeyEnum.PAY_INCREASE]: [
        {
          id: 0,
          isAffected: false,
          title: '0-3 months',
          employeesCount: 120,
          medianTenure: 2,
          beyondMedianTenure: 51,
          sincePromotion: 2,
          sincePayIncrease: 2,
          riskOfAttrition: 10,
          engagementDifference: -12,
          productivityDifference: -11,
          burnoutDifference: -17,
          retentionSaving: 23920,
          topPerformersInRisk: 1,
        },
        {
          id: 1,
          isAffected: false,
          title: '3-6 months',
          employeesCount: 70,
          medianTenure: 5,
          beyondMedianTenure: 22,
          sincePromotion: 2,
          sincePayIncrease: 4,
          riskOfAttrition: 16,
          engagementDifference: -2,
          productivityDifference: -6.5,
          burnoutDifference: -13,
          retentionSaving: 62840,
          topPerformersInRisk: 2,
        },
        {
          id: 2,
          isAffected: false,
          title: '6-9 months',
          employeesCount: 88,
          medianTenure: 17,
          beyondMedianTenure: 11,
          sincePromotion: 7,
          sincePayIncrease: 8,
          riskOfAttrition: 17,
          engagementDifference: -3.5,
          productivityDifference: -11,
          burnoutDifference: -15,
          retentionSaving: 348800,
          topPerformersInRisk: 9,
        },
        {
          id: 3,
          isAffected: false,
          title: '9-12 months',
          employeesCount: 72,
          medianTenure: 39,
          beyondMedianTenure: 6,
          sincePromotion: 11,
          sincePayIncrease: 11,
          riskOfAttrition: 19,
          engagementDifference: -2.3,
          productivityDifference: -7,
          burnoutDifference: -4,
          retentionSaving: 67840,
          topPerformersInRisk: 2,
        },
        {
          id: 4,
          isAffected: false,
          title: '12-18 months',
          employeesCount: 49,
          medianTenure: 42,
          beyondMedianTenure: 8,
          sincePromotion: 14,
          sincePayIncrease: 13,
          riskOfAttrition: 15,
          engagementDifference: -2.3,
          productivityDifference: -7,
          burnoutDifference: -4,
          retentionSaving: 67840,
          topPerformersInRisk: 2,
        },
        {
          id: 5,
          isAffected: false,
          title: '18-24 months',
          employeesCount: 37,
          medianTenure: 65,
          beyondMedianTenure: 3,
          sincePromotion: 19,
          sincePayIncrease: 20,
          riskOfAttrition: 12,
          engagementDifference: 7,
          productivityDifference: 9,
          burnoutDifference: 5.5,
          retentionSaving: 43840,
          topPerformersInRisk: 1,
        },
        {
          id: 6,
          isAffected: false,
          title: '24-30 months',
          employeesCount: 25,
          medianTenure: 88,
          beyondMedianTenure: 8,
          sincePromotion: 26,
          sincePayIncrease: 25,
          riskOfAttrition: 10,
          engagementDifference: 23,
          productivityDifference: 33,
          burnoutDifference: 13,
          retentionSaving: 82870,
          topPerformersInRisk: 2,
        },
        {
          id: 7,
          isAffected: false,
          title: '30-36 months',
          employeesCount: 38,
          medianTenure: 82,
          beyondMedianTenure: 11,
          sincePromotion: 32,
          sincePayIncrease: 30,
          riskOfAttrition: 5,
          engagementDifference: 3.2,
          productivityDifference: 4,
          burnoutDifference: -3.5,
          retentionSaving: 0,
          topPerformersInRisk: 0,
        },
      ],

      [FilterKeyEnum.PROMOTION]: [
        {
          id: 0,
          isAffected: false,
          title: '0-3 months',
          employeesCount: 120,
          medianTenure: 2,
          beyondMedianTenure: 51,
          sincePromotion: 2,
          sincePayIncrease: 2,
          riskOfAttrition: 10,
          engagementDifference: -12,
          productivityDifference: -11,
          burnoutDifference: -17,
          retentionSaving: 23920,
          topPerformersInRisk: 1,
        },
        {
          id: 1,
          isAffected: false,
          title: '3-6 months',
          employeesCount: 70,
          medianTenure: 5,
          beyondMedianTenure: 22,
          sincePromotion: 2,
          sincePayIncrease: 4,
          riskOfAttrition: 16,
          engagementDifference: -2,
          productivityDifference: -6.5,
          burnoutDifference: -13,
          retentionSaving: 62840,
          topPerformersInRisk: 2,
        },
        {
          id: 2,
          isAffected: false,
          title: '6-9 months',
          employeesCount: 88,
          medianTenure: 17,
          beyondMedianTenure: 11,
          sincePromotion: 7,
          sincePayIncrease: 8,
          riskOfAttrition: 17,
          engagementDifference: -3.5,
          productivityDifference: -11,
          burnoutDifference: -15,
          retentionSaving: 348800,
          topPerformersInRisk: 9,
        },
        {
          id: 3,
          isAffected: false,
          title: '9-12 months',
          employeesCount: 72,
          medianTenure: 39,
          beyondMedianTenure: 6,
          sincePromotion: 11,
          sincePayIncrease: 11,
          riskOfAttrition: 19,
          engagementDifference: -2.3,
          productivityDifference: -7,
          burnoutDifference: -4,
          retentionSaving: 67840,
          topPerformersInRisk: 2,
        },
        {
          id: 4,
          isAffected: false,
          title: '12-18 months',
          employeesCount: 49,
          medianTenure: 42,
          beyondMedianTenure: 8,
          sincePromotion: 14,
          sincePayIncrease: 13,
          riskOfAttrition: 15,
          engagementDifference: -2.3,
          productivityDifference: -7,
          burnoutDifference: -4,
          retentionSaving: 67840,
          topPerformersInRisk: 2,
        },
        {
          id: 5,
          isAffected: false,
          title: '18-24 months',
          employeesCount: 37,
          medianTenure: 65,
          beyondMedianTenure: 3,
          sincePromotion: 19,
          sincePayIncrease: 20,
          riskOfAttrition: 12,
          engagementDifference: 7,
          productivityDifference: 9,
          burnoutDifference: 5.5,
          retentionSaving: 43840,
          topPerformersInRisk: 1,
        },
        {
          id: 6,
          isAffected: false,
          title: '24-30 months',
          employeesCount: 25,
          medianTenure: 88,
          beyondMedianTenure: 8,
          sincePromotion: 26,
          sincePayIncrease: 25,
          riskOfAttrition: 10,
          engagementDifference: 23,
          productivityDifference: 33,
          burnoutDifference: 13,
          retentionSaving: 82870,
          topPerformersInRisk: 2,
        },
        {
          id: 7,
          isAffected: false,
          title: '30-36 months',
          employeesCount: 38,
          medianTenure: 82,
          beyondMedianTenure: 11,
          sincePromotion: 32,
          sincePayIncrease: 30,
          riskOfAttrition: 5,
          engagementDifference: 3.2,
          productivityDifference: 4,
          burnoutDifference: -3.5,
          retentionSaving: 0,
          topPerformersInRisk: 0,
        },
      ],

      [FilterKeyEnum.CITY]: [
        {
          id: 0,
          isAffected: false,
          title: 'New York',
          employeesCount: 150,
          medianTenure: 20,
          beyondMedianTenure: 70,
          sincePromotion: 12,
          sincePayIncrease: 10,
          riskOfAttrition: 20,
          engagementDifference: 3.2,
          productivityDifference: 4,
          burnoutDifference: -3.5,
          retentionSaving: 248800,
          topPerformersInRisk: 4,
        },
        {
          id: 1,
          isAffected: false,
          title: 'San Francisco',
          employeesCount: 433,
          medianTenure: 12,
          beyondMedianTenure: 212,
          sincePromotion: 6,
          sincePayIncrease: 8,
          riskOfAttrition: 50,
          engagementDifference: 15,
          productivityDifference: 25,
          burnoutDifference: -10,
          retentionSaving: 2489800,
          topPerformersInRisk: 20,
        },
        {
          id: 2,
          isAffected: false,
          title: 'Denver',
          employeesCount: 88,
          medianTenure: 30,
          beyondMedianTenure: 20,
          sincePromotion: 9,
          sincePayIncrease: 9,
          riskOfAttrition: 8,
          engagementDifference: 40,
          productivityDifference: 33,
          burnoutDifference: -25,
          retentionSaving: 289800,
          topPerformersInRisk: 3,
        },
        {
          id: 3,
          isAffected: false,
          title: 'Austin',
          employeesCount: 37,
          medianTenure: 22,
          beyondMedianTenure: 20,
          sincePromotion: 9,
          sincePayIncrease: 9,
          riskOfAttrition: 4,
          engagementDifference: -80,
          productivityDifference: -60,
          burnoutDifference: 40,
          retentionSaving: 102400,
          topPerformersInRisk: 1,
        },
      ],

      [FilterKeyEnum.DEPARTMENT]: [
        {
          id: 0,
          isAffected: false,
          title: 'Sales',
          employeesCount: 320,
          medianTenure: 20,
          beyondMedianTenure: 70,
          sincePromotion: 12,
          sincePayIncrease: 10,
          riskOfAttrition: 20,
          engagementDifference: 3.2,
          productivityDifference: 4,
          burnoutDifference: -3.5,
          retentionSaving: 248800,
          topPerformersInRisk: 4,
        },
        {
          id: 1,
          isAffected: false,
          title: 'Engineering',
          employeesCount: 588,
          medianTenure: 12,
          beyondMedianTenure: 212,
          sincePromotion: 6,
          sincePayIncrease: 8,
          riskOfAttrition: 50,
          engagementDifference: 15,
          productivityDifference: 25,
          burnoutDifference: -10,
          retentionSaving: 2489800,
          topPerformersInRisk: 20,
        },
        {
          id: 2,
          isAffected: false,
          title: 'HR',
          employeesCount: 76,
          medianTenure: 30,
          beyondMedianTenure: 20,
          sincePromotion: 9,
          sincePayIncrease: 9,
          riskOfAttrition: 8,
          engagementDifference: 40,
          productivityDifference: 33,
          burnoutDifference: -25,
          retentionSaving: 289800,
          topPerformersInRisk: 3,
        },
        {
          id: 3,
          isAffected: false,
          title: 'Marketing',
          employeesCount: 120,
          medianTenure: 22,
          beyondMedianTenure: 20,
          sincePromotion: 9,
          sincePayIncrease: 9,
          riskOfAttrition: 4,
          engagementDifference: -80,
          productivityDifference: -60,
          burnoutDifference: 40,
          retentionSaving: 102400,
          topPerformersInRisk: 1,
        },
      ],

      [FilterKeyEnum.TEAM_MANAGER]: [
        {
          title: 'Gavin Belson',
          employeesCount: 87,
          isAffected: true,
          id: 1,

          medianTenure: 0.9,
          promotionCount: 10,
          beyondMedianTenure: 3.5,
          sincePromotion: 3,
          sincePayIncrease: 2,
          riskOfAttrition: 10,
          topPerformersInRisk: 5,
          engagementDifference: -25,
          productivityDifference: -34,
          burnoutDifference: 15,
          retentionSaving: 400000,
        },
        {
          title: 'Richard Hendricks',
          employeesCount: 75,
          isAffected: false,
          id: 2,

          medianTenure: 1.9,
          promotionCount: 10,
          beyondMedianTenure: 21,
          sincePromotion: 1.3,
          sincePayIncrease: 4,
          riskOfAttrition: 6,
          topPerformersInRisk: 2,
          engagementDifference: 8,
          productivityDifference: 17,
          burnoutDifference: 3,
          retentionSaving: 160000,
        },
        {
          title: 'Erlich Bachman',
          employeesCount: 65,
          isAffected: false,
          id: 3,

          medianTenure: 2.1,
          promotionCount: 10,
          beyondMedianTenure: 58,
          sincePromotion: 0.3,
          sincePayIncrease: 0.4,
          riskOfAttrition: 3,
          topPerformersInRisk: 1,
          engagementDifference: 34,
          productivityDifference: 48,
          burnoutDifference: 23,
          retentionSaving: 80000,
        },
        {
          title: 'Dinesh Chugtai',
          employeesCount: 73,
          isAffected: false,
          id: 4,

          medianTenure: 1.4,
          promotionCount: 10,
          beyondMedianTenure: 24,
          sincePromotion: 1.7,
          sincePayIncrease: 6.5,
          riskOfAttrition: 0,
          topPerformersInRisk: 0,
          engagementDifference: 5,
          productivityDifference: 2,
          burnoutDifference: -2,
          retentionSaving: 0,
        },
        {
          title: 'Jack Barker',
          employeesCount: 94,
          isAffected: true,
          id: 5,

          medianTenure: 1.1,
          promotionCount: 10,
          beyondMedianTenure: 36,
          sincePromotion: 5,
          sincePayIncrease: 2.2,
          riskOfAttrition: 4,
          topPerformersInRisk: 2,
          engagementDifference: -13,
          productivityDifference: -9,
          burnoutDifference: 7,
          retentionSaving: 160000,
        },
        {
          title: 'Bertram Gilfoyle',
          employeesCount: 98,
          isAffected: false,
          id: 6,

          medianTenure: 1.8,
          promotionCount: 10,
          beyondMedianTenure: 50,
          sincePromotion: 3.2,
          sincePayIncrease: 4.7,
          riskOfAttrition: 16,
          topPerformersInRisk: 9,
          engagementDifference: -45,
          productivityDifference: -60,
          burnoutDifference: 31,
          retentionSaving: 720000,
        },
      ],
    };
  }
}
