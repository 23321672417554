import { ProductivityFacade } from '@/packages/productivity/productivity.facade';

export default defineNuxtPlugin(() => {
  const productivity = new ProductivityFacade();

  return {
    provide: {
      productivity,
    },
  };
});
