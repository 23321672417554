export enum LoginErrorEnum {
  PLATFORM_AUTHENTICATION_STRATEGY_IS_NOT_ALLOWED = 'PLATFORM_AUTHENTICATION_STRATEGY_IS_NOT_ALLOWED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_ACTIVE_BUT_INVITED = 'USER_NOT_ACTIVE_BUT_INVITED',
  USER_NOT_ACTIVE = 'USER_NOT_ACTIVE',
  ORGANISATION_AUTHENTICATION_STRATEGY_IS_NOT_ALLOWED = 'ORGANISATION_AUTHENTICATION_STRATEGY_IS_NOT_ALLOWED',
  BAD_REQUEST_PROVIDED = 'BAD_REQUEST_PROVIDED',
  UNKNOWN_AUTH_EXCEPTION = 'UNKNOWN_AUTH_EXCEPTION',
  OIDC_AUTH_EXCEPTION = 'OIDC_AUTH_EXCEPTION',
}

export enum LoginStatusEnum {
  FAIL = 'fail',
  SUCCESS = 'success',
}

export enum LoginTypeEnum {
  PERFORMETRY_SIGN_IN = 'PERFORMETRY_SIGN_IN',
  MICROSOFT_SSO = 'MICROSOFT_SSO',
  GOOGLE_SSO = 'GOOGLE_SSO',
}

export enum ResetPasswordErrorEnum {
  UNKNOWN_PWD_RESTORATION_EXCEPTION = 'UNKNOWN_PWD_RESTORATION_EXCEPTION',
  BAD_REQUEST_PROVIDED = 'BAD_REQUEST_PROVIDED',
  PWD_RESET_REQUEST_NOT_EXISTS = 'PWD_RESET_REQUEST_NOT_EXISTS',
  PWD_RESTORATION_NOT_ALLOWED = 'PWD_RESTORATION_NOT_ALLOWED',
  WRONG_TOKEN = 'WRONG_TOKEN',
}
