import { AppService } from '~/app/app.service';
import { Faker } from '@/packages/fake-data/faker';
import { AlertFakeData } from '~/packages/attrition/fake-data/alert.fake-data';

import { AlertType } from '@/packages/attrition/types/alert.type';
import { AlertTeamType } from '@/packages/attrition/types/alert-team.type';
import { TeamMemberType } from '@/packages/attrition/types/team-member.type';
import { SurveyStatusEnum } from '@/packages/attrition/enums/survey-status.enum';
import { AlertStatusService } from '@/packages/attrition/services/alert-status.service';
import finalSurveyInventory from '@/packages/attrition/inventory/survey/final-survey.inventory';
import initialSurveyInventory from '@/packages/attrition/inventory/survey/initial-survey.inventory';
import performanceSurveyInventory from '@/packages/attrition/inventory/survey/performance-survey.inventory';

export class AlertService extends AppService {
  public async fetchAlerts(): Promise<AlertType[]> {
    if (Faker.isEnabled()) {
      const alerts = await AlertFakeData.getAlerts();
      Object.values(alerts).forEach(AlertService.addQuestions);
      Object.values(alerts).forEach(AlertService.addStatus);

      return alerts;
    }

    return this.api.core
      .get('/attrition/alert/')
      .then((response: any) =>
        response.data.payload.alerts.map(AlertService.addQuestions).map(AlertService.addStatus)
      );
  }

  public async fetchAlert(alertId: string, isDemo = false): Promise<AlertType> {
    if (Faker.isEnabled() || isDemo) {
      let alert = await AlertFakeData.getAlert(alertId);

      alert = AlertService.addQuestions(alert);
      alert = AlertService.addStatus(alert);

      return alert;
    }

    return this.api.core
      .get(`/attrition/alert/${alertId}`)
      .then((response: any) => response.data.payload.alert);
  }

  public getManagers(alert: AlertType | null): TeamMemberType[] {
    let managers: TeamMemberType[] = [];

    Object.values(alert?.teams || {}).forEach(team => {
      managers = [...managers, ...this.getTeamManagers(team)];
    });

    return managers;
  }

  public getEmployees(alert: AlertType | null): TeamMemberType[] {
    let employees: TeamMemberType[] = [];

    Object.values(alert?.teams || {}).forEach(team => {
      employees = [...employees, ...this.getEmployeesFromTeam(team)];
    });

    return employees;
  }

  public getTeamManagers(team: AlertTeamType): TeamMemberType[] {
    return Object.values(team.members).filter(member => member.isManager);
  }

  public getEmployeesFromTeam(team: AlertTeamType): TeamMemberType[] {
    return Object.values(team.members).filter(member => !member.isManager);
  }

  public findManager(alert: AlertType, managerId: string): TeamMemberType | null {
    const managers = this.getManagers(alert);
    return managers.find(manager => manager.id === managerId) || null;
  }

  public notStarted(alert: AlertType) {
    return (
      alert?.performanceSurvey?.status === SurveyStatusEnum.NOT_SENT &&
      alert?.initialSurvey?.status === SurveyStatusEnum.NOT_SENT
    );
  }

  private static addQuestions(alert: AlertType) {
    const companyName = Faker.isEnabled() ? 'Pied Piper' : '{{company}}';
    const payload = { companyName };

    alert.finalSurvey.questions = finalSurveyInventory.admin.questions(payload);
    alert.initialSurvey.questions = initialSurveyInventory.admin.questions(payload);
    alert.performanceSurvey.questions = performanceSurveyInventory.admin.questions(payload);

    return alert;
  }

  private static addStatus(alert: AlertType) {
    alert.status = AlertStatusService.getStatus(alert);

    return alert;
  }
}
