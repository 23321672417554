export enum ReasonsKeyEnum {
  MANAGER = 'MANAGER',
  COLLEAGUES = 'COLLEAGUES',
  ROLE = 'ROLE',
  LACK_OF_PROGRESSION = 'LACK_OF_PROGRESSION',
  LACK_OF_OPPORTUNITIES = 'LACK_OF_OPPORTUNITIES',
  HIGHER_PAY = 'HIGHER_PAY',
  COMPANY_VISION = 'COMPANY_VISION',
  COMPANY_CULTURE = 'COMPANY_CULTURE',
  LEADERSHIP = 'LEADERSHIP',
  BURNOUT = 'BURNOUT',
  WORK_LIFE_BALANCE = 'WORK_LIFE_BALANCE',
  OTHER = 'OTHER',
}
