export enum AttritionAlertPermissionEnum {
  ATTRITION_ALERT_ALERT_CREATE = 'ATTRITION_ALERT_ALERT_CREATE',
  ATTRITION_ALERT_ALERT_VIEW = 'ATTRITION_ALERT_ALERT_VIEW',
  ATTRITION_ALERT_MANAGER_SURVEY_SEND = 'ATTRITION_ALERT_MANAGER_SURVEY_SEND',
  ATTRITION_ALERT_MANAGER_SURVEY_REMINDER_SEND = 'ATTRITION_ALERT_MANAGER_SURVEY_REMINDER_SEND',
  ATTRITION_ALERT_MANAGER_SURVEY_EMAIL_SEND = 'ATTRITION_ALERT_MANAGER_SURVEY_EMAIL_SEND',
  ATTRITION_ALERT_FIRST_EMPLOYEE_SURVEY_SEND = 'ATTRITION_ALERT_FIRST_EMPLOYEE_SURVEY_SEND',
  ATTRITION_ALERT_FIRST_EMPLOYEE_SURVEY_REMINDER_SEND = 'ATTRITION_ALERT_FIRST_EMPLOYEE_SURVEY_REMINDER_SEND',
  ATTRITION_ALERT_FIRST_EMPLOYEE_SURVEY_EMAIL_SEND = 'ATTRITION_ALERT_FIRST_EMPLOYEE_SURVEY_EMAIL_SEND',
  ATTRITION_ALERT_SECOND_EMPLOYEE_SURVEY_SEND = 'ATTRITION_ALERT_SECOND_EMPLOYEE_SURVEY_SEND',
  ATTRITION_ALERT_SECOND_EMPLOYEE_SURVEY_REMINDER_SEND = 'ATTRITION_ALERT_SECOND_EMPLOYEE_SURVEY_REMINDER_SEND',
  ATTRITION_ALERT_SECOND_EMPLOYEE_SURVEY_EMAIL_SEND = 'ATTRITION_ALERT_SECOND_EMPLOYEE_SURVEY_EMAIL_SEND',
  ATTRITION_ALERT_SUGGESTION_SEND = 'ATTRITION_ALERT_SUGGESTION_SEND',
  ATTRITION_ALERT_SUGGESTION_REMINDER_SEND = 'ATTRITION_ALERT_SUGGESTION_REMINDER_SEND',
  ATTRITION_ALERT_HEATMAP_VIEW = 'ATTRITION_ALERT_HEATMAP_VIEW',
  ATTRITION_ALERT_HEATMAP_VIEW_USERS = 'ATTRITION_ALERT_HEATMAP_VIEW_USERS',
  ATTRITION_ALERT_HEATMAP_VIEW_TREND = 'ATTRITION_ALERT_HEATMAP_VIEW_TREND',
  ATTRITION_ALERT_HEATMAP_SHARE_EMAIL = 'ATTRITION_ALERT_HEATMAP_SHARE_EMAIL',
  ATTRITION_ALERT_HEATMAP_DOWNLOAD_PDF = 'ATTRITION_ALERT_HEATMAP_DOWNLOAD_PDF',
  ATTRITION_ALERT_SURVEY_RESULT_VIEW = 'ATTRITION_ALERT_SURVEY_RESULT_VIEW',
  ATTRITION_ALERT_SURVEY_RESULT_SHARE_EMAIL = 'ATTRITION_ALERT_SURVEY_RESULT_SHARE_EMAIL',
  ATTRITION_ALERT_SURVEY_RESULT_DOWNLOAD_PDF = 'ATTRITION_ALERT_SURVEY_RESULT_DOWNLOAD_PDF',
  ATTRITION_ALERT_DRIVER_VIEW = 'ATTRITION_ALERT_DRIVER_VIEW',
  ATTRITION_ALERT_DRIVER_SHARE_EMAIL = 'ATTRITION_ALERT_DRIVER_SHARE_EMAIL',
  ATTRITION_ALERT_DRIVER_DOWNLOAD_PDF = 'ATTRITION_ALERT_DRIVER_DOWNLOAD_PDF',
  ATTRITION_ALERT_RESULT_VIEW = 'ATTRITION_ALERT_RESULT_VIEW',
  ATTRITION_ALERT_RESULT_SHARE_EMAIL = 'ATTRITION_ALERT_RESULT_SHARE_EMAIL',
  ATTRITION_ALERT_RESULT_DOWNLOAD_PDF = 'ATTRITION_ALERT_RESULT_DOWNLOAD_PDF',
}

export enum AttritionComparisonResultPermissionEnum {
  ATTRITION_COMPARISON_RESULT_VIEW = 'ATTRITION_COMPARISON_RESULT_VIEW',
  ATTRITION_COMPARISON_RESULT_SHARE_EMAIL = 'ATTRITION_COMPARISON_RESULT_SHARE_EMAIL',
  ATTRITION_COMPARISON_RESULT_DOWNLOAD_PDF = 'ATTRITION_COMPARISON_RESULT_DOWNLOAD_PDF',
}

export enum AttritionDriverPermissionEnum {
  ATTRITION_DRIVER_VIEW = 'ATTRITION_DRIVER_VIEW',
  ATTRITION_DRIVER_SHARE_EMAIL = 'ATTRITION_DRIVER_SHARE_EMAIL',
  ATTRITION_DRIVER_DOWNLOAD_PDF = 'ATTRITION_DRIVER_DOWNLOAD_PDF',
}

export enum AttritionHeatmapPermissionEnum {
  ATTRITION_HEATMAP_VIEW = 'ATTRITION_HEATMAP_VIEW',
  ATTRITION_HEATMAP_VIEW_USERS = 'ATTRITION_HEATMAP_VIEW_USERS',
  ATTRITION_HEATMAP_VIEW_TREND = 'ATTRITION_HEATMAP_VIEW_TREND',
  ATTRITION_HEATMAP_SHARE_EMAIL = 'ATTRITION_HEATMAP_SHARE_EMAIL',
  ATTRITION_HEATMAP_DOWNLOAD_PDF = 'ATTRITION_HEATMAP_DOWNLOAD_PDF',
}

export enum AttritionMonetaryResultPermissionEnum {
  ATTRITION_MONETARY_RESULT_VIEW = 'ATTRITION_MONETARY_RESULT_VIEW',
  ATTRITION_MONETARY_RESULT_SHARE_EMAIL = 'ATTRITION_MONETARY_RESULT_SHARE_EMAIL',
  ATTRITION_MONETARY_RESULT_DOWNLOAD_PDF = 'ATTRITION_MONETARY_RESULT_DOWNLOAD_PDF',
}

export enum AttritionOverviewEnum {
  ATTRITION_RISK_OVERVIEW = 'ATTRITION_RISK_OVERVIEW',
}

export default {
  ...AttritionAlertPermissionEnum,
  ...AttritionComparisonResultPermissionEnum,
  ...AttritionDriverPermissionEnum,
  ...AttritionHeatmapPermissionEnum,
  ...AttritionMonetaryResultPermissionEnum,
  ...AttritionOverviewEnum,
};
