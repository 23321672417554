import { AxiosError } from 'axios';
import { generatePdfProps, sendPdfProps, PdfService } from './pdf.service';
import { AppFacade } from '~/app/app.facade';

export class PdfFacade extends AppFacade {
  readonly service = new PdfService();

  async generateAndDownload(filename: string, generateProps: generatePdfProps) {
    try {
      const response = await this.service.generatePdf(generateProps);
      this.download(filename, response.data);
    } catch (e) {
      const app = useNuxtApp();
      app.$ui.snackbar.showAPIError(e as AxiosError);
    }
  }

  async generateAndSend(
    filename: string,
    generateProps: generatePdfProps,
    sendProps: sendPdfProps
  ) {
    try {
      const response = await this.service.generatePdf(generateProps);

      return this.service.sendPdf({ data: response.data, filename }, sendProps);
    } catch (e) {
      const app = useNuxtApp();
      app.$ui.snackbar.showAPIError(e as AxiosError);
    }
  }

  private download(filename: string, data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.pdf`);

    document.body.appendChild(link);

    link.click();

    link.remove();
  }
}
