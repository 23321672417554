import { Trend, TrendPoint } from '~/shared/ui/chart/trend/types/trend.types';
import { generateMidpointDisplacement } from '~/shared/helpers/algorithm/midpoint-displacement';

export function generateTrend(options: {
  metricUid: string;
  length: number;
  startPoint: number;
  year: number;
  min: number;
  max: number;
  roughness?: number;
}): Trend {
  let totalValue = 0;
  const dif = options.max - options.min;
  const md = generateMidpointDisplacement(options.length, options.roughness || 0.01);

  const points: TrendPoint[] = md.map((k, index) => {
    const value = Math.max(Math.round(options.min + dif * k), options.min);
    totalValue += value;

    return {
      value,
      point: options.startPoint + index,
      year: options.year,
    };
  });

  return {
    uid: options.metricUid,
    points,
    totalValue,
  };
}
