import { ref } from 'vue';

export class UserLocaleService {
  public date = ref(new Date());
  public dateExactMinutes = new Date();

  private S_IN_MINUTE = 60;
  private MS_IN_MINUTE = 60000;
  private MS_IN_SECOND = 1000;

  constructor() {
    this.startUpdatingDate();
    this.startUpdatingDateExactMinutes();
  }

  private startUpdatingDate() {
    setInterval(this.updateDate.bind(this), this.MS_IN_SECOND);
  }

  private startUpdatingDateExactMinutes() {
    const sToEndOfMinute = this.S_IN_MINUTE - this.date.value.getSeconds();

    setTimeout(() => {
      this.updateDateExactMinutes();

      setInterval(this.updateDateExactMinutes.bind(this), this.MS_IN_MINUTE);
    }, sToEndOfMinute * this.MS_IN_SECOND);
  }

  private updateDate() {
    this.date.value = new Date();
  }

  private updateDateExactMinutes() {
    this.dateExactMinutes = new Date();
  }
}
