import dayjs from '~/shared/utils/dayjs';
// TODO: Review and del
// import utcPlugin from 'dayjs/plugin/utc';
import { SurveyTaskType } from '@/packages/attrition/types/survey-task.type';
import { SurveyStatusEnum } from '@/packages/attrition/enums/survey-status.enum';
import { SuggestionTaskType } from '@/packages/attrition/types/suggestion-task.type';
import { TeamMemberSurveyType } from '@/packages/attrition/types/team-member-survey.type';

// dayjs.extend(utcPlugin);

function isTaskDismissedByAdmin(task: SurveyTaskType | SuggestionTaskType | TeamMemberSurveyType) {
  const now = dayjs.utc();
  const isNotSent = task?.status === SurveyStatusEnum.NOT_SENT;

  return now.isAfter(dayjs.utc(task?.createDeadline)) && isNotSent;
}

export default isTaskDismissedByAdmin;
