import axios, { AxiosInstance, AxiosError } from 'axios';
import axiosRetry, { isNetworkError } from 'axios-retry';

interface Options {
  baseUrl?: string;
  apiVersion?: string;
  retry?: boolean;
}

export class RestApi {
  public get;
  public post;
  public patch;
  public delete;

  private readonly instance: AxiosInstance;
  private readonly baseUrl: string;

  constructor(options: Options) {
    this.baseUrl = this.getBaseUrl(options);

    this.instance = axios.create({
      baseURL: this.baseUrl,
      withCredentials: true,
    });

    if (options.retry) {
      this.setRetry();
    }

    this.get = this.instance.get;
    this.post = this.instance.post;
    this.patch = this.instance.patch;
    this.delete = this.instance.delete;
  }

  private getBaseUrl(options: Options) {
    if (options.baseUrl) {
      return options.baseUrl;
    }

    const location = window.location;

    return `${location.protocol}//${location.host}`;
  }

  private setRetry() {
    axiosRetry(this.instance, {
      retries: 5,
      retryDelay: retryCount => {
        return retryCount * 1000;
      },
      retryCondition: error => {
        console.log('axiosRetry');
        console.log('error.code: ', error.code);
        console.log('isNetworkError: ', isNetworkError(error));

        if (!error.response || error.response?.status === 503) {
          return true;
        }

        return isNetworkError(error);
      },
    });
  }

  public addResponseInterceptor(interceptor: (error: AxiosError) => Promise<AxiosError>): number {
    return this.instance.interceptors.response.use(undefined, interceptor);
  }

  public removeResponseInterceptor(id: number) {
    this.instance.interceptors.response.eject(id);
  }
}
