import { AppService } from '~/app/app.service';

import { Faker } from '@/packages/fake-data/faker';
import { DriversType } from '@/packages/attrition/types/drivers.type';
import { DriversFakeData } from '~/packages/attrition/fake-data/drivers.fake-data';
import { DriversDetailsType } from '@/packages/attrition/types/drivers-details.type';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { DatesRangeDto, TargetingDto } from '~/shared/api/types/request/common.types';

export type DriversDto = DatesRangeDto;

export type DetailsDto = DatesRangeDto & TargetingDto;

export class DriversService extends AppService {
  public fetchDrivers(params: DriversDto, isDemo = false): Promise<DriversType> {
    if (Faker.isEnabled() || isDemo) {
      return DriversFakeData.getDrivers();
    }

    return this.api.core
      .get(`/attrition/drivers/benchmark`, { params })
      .then((response: any) => response.data.payload.data);
  }

  public fetchDetails(options: DetailsDto, isDemo = false): Promise<DriversDetailsType> {
    if (Faker.isEnabled() || isDemo) {
      return DriversFakeData.getDetails();
    }

    return this.api.core
      .post(`/attrition/drivers/user-dropdown`, options)
      .then((response: any) => response.data.payload.data);
  }
}
