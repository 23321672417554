import { IntegrationFacade } from '@/packages/integration/integration.facade';

export default defineNuxtPlugin(() => {
  const facade = new IntegrationFacade();

  return {
    provide: {
      integration: facade,
    },
  };
});
