import permissionAttritionEnums from './permission/permission.attrition.enums';
import permissionSegmentEnums from './permission/permission.segment.enums';
import permissionProductivityEnums from './permission/permission.productivity.enums';
import permissionIntegrationEnums from './permission/permission.integration.enums';
import permissionSurveyEnums from './permission/permission.survey.enums';
import permissionPeopleEnums from './permission/permission.people.enums';
import permissionRevenueEnums from './permission/permission.revenue.enums';

export const PermissionEnum = {
  ...permissionAttritionEnums,
  ...permissionSegmentEnums,
  ...permissionProductivityEnums,
  ...permissionIntegrationEnums,
  ...permissionSurveyEnums,
  ...permissionPeopleEnums,
  ...permissionRevenueEnums,
};
