export default seconds => {
  if (seconds < 60) {
    return `${seconds} seconds`;
  }

  const minutes = Math.ceil(seconds / 60);
  const label = minutes === 1 ? 'minute' : 'minutes';

  return `${minutes} ${label}`;
};
