import pluralForm from './plural-form';

export default function pluralize(count: number, text: string | [string, string]): string {
  if (typeof text === 'string' || Array.isArray(text)) {
    return `${count} ${pluralForm(count, text)}`;
  }

  console.error('wrong arguments in pluralize', { count, text });
  return count.toString();
}
