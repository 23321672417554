import { ErrorReporter, User } from '../error-reporter';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

export class SentryErrorReporter implements ErrorReporter {
  constructor(options: {
    vueApp: any;
    vueRouter: any;
    dsn: string;
    enabled: boolean;
    release: string;
  }) {
    Sentry.init({
      app: options.vueApp,
      enabled: options.enabled,
      dsn: options.dsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(options.vueRouter),
          tracePropagationTargets: ['localhost', 'performetry.ai', /^\//],
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1,

      release: options.release,

      ignoreErrors: ['Request failed with status code 401'],
    });
  }

  log(error: Error) {
    Sentry.captureException(error);
  }

  setUser(user: User) {
    Sentry.setUser(user);
  }
}
