import { useProductivityTrendStore } from './store/productivity-trend.store';
import { useProductivityHeatmapStore } from './store/productivity-heatmap.store';
import { AppFacade } from '~/app/app.facade';

import { HeatmapService } from './services/heatmap.service';
import { TrendsService } from './services/trends.service';

export class ProductivityFacade extends AppFacade {
  private readonly heatmapService = new HeatmapService();
  private readonly trendsService = new TrendsService();
  readonly heatmapStore;
  readonly trendStore;

  constructor() {
    super();

    this.heatmapStore = useProductivityHeatmapStore();
    this.trendStore = useProductivityTrendStore();
  }

  public getHeatmapService(): HeatmapService {
    return this.heatmapService;
  }

  public getTrendsService(): TrendsService {
    return this.trendsService;
  }
}
