import { AppService } from '../../../app/app.service';
import { SegmentCategoryType } from '~/packages/employees/inventory/employees-segmentation.inventory';
import { Faker } from '@/packages/fake-data/faker';
import { FilterOptionType } from '@/packages/filter/types/filter-option.type';
import { SegmentAnalyticsFakeService } from '@/packages/fake-data/segment/segment-analytics.fake-service';
import { DatesRangeDto, TargetingDto } from '~/shared/api/types/request/common.types';

export type CategoriesDTO = Required<TargetingDto> & { dates: DatesRangeDto };

export class EmployeesSegmentationApi extends AppService {
  async fetchFilters(payload: {
    dates: { starts: string; ends: string };
  }): Promise<FilterOptionType[]> {
    if (Faker.isEnabled()) {
      return Promise.resolve(SegmentAnalyticsFakeService.getFilters());
    }

    const response = await this.api.core.post('/segments/filters', payload);

    return response.data.payload.filters;
  }

  async fetchCategories(dto: CategoriesDTO): Promise<Record<string, SegmentCategoryType>> {
    if (Faker.isEnabled()) {
      return Promise.resolve(SegmentAnalyticsFakeService.getCategories(dto.targeting));
    }

    const response = await this.api.core.post('/segments/analytics', dto);

    return response.data.payload.data;
  }
}
