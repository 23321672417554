<template>
  <ContainerBase>
    <div class="header-logo">
      <img v-if="logoSrc" :src="logoSrc" alt="Logo" class="header-logo__img" />
      <AppLogo v-else class="header-logo__img" />

      <DividerLine v-if="divider" class="header-logo__divider"></DividerLine>
    </div>
  </ContainerBase>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import DividerLine from '~/shared/ui/divider/divider-line.vue';
import AppLogo from '~/shared/ui/app/app-logo.vue';
import ContainerBase from '~/shared/ui/container/container-base.vue';
import { OrganisationLogoItemType } from '~/packages/user/types/organisation.types';
import { getOrganisationLogoSrc } from '~/packages/user/helpers/organisation.helpers';

export default defineComponent({
  name: 'AppHeaderLogo',

  components: { ContainerBase, AppLogo, DividerLine },

  props: {
    logo: {
      type: [Object, String] as PropType<OrganisationLogoItemType>,
      default: null,
    },

    divider: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    logoSrc(): string | null {
      return getOrganisationLogoSrc(this.logo);
    },
  },
});
</script>

<style lang="scss">
.header-logo {
  display: flex;
  justify-content: center;
  margin: 64px 0 32px;
  width: 100%;
  flex-direction: column;

  @media (max-width: theme('screens.md')) {
    justify-content: flex-start;
    margin: 20px 0;
  }

  &__img {
    height: 20px !important;

    @media (max-width: theme('screens.md')) {
      align-self: flex-start;
    }
  }

  &__divider {
    margin-top: 32px;

    @media (max-width: theme('screens.md')) {
      margin-top: 20px;
    }
  }
}
</style>
