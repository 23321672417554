import { DatePickerState } from '../types/store.type';
import { DatePickerOptionType } from '../ui/form/date-picker/types/date-picker-option.type';
import { DatesRangeType } from '../ui/form/date-picker/types/dates-range.type';

export const useDatePickerState = (): DatePickerState => {
  const selectedOption = ref<DatePickerOptionType>();

  const selectedDates = computed<DatesRangeType | undefined>(() => selectedOption.value?.dates);

  function setSelectedOption(option: DatePickerOptionType) {
    selectedOption.value = option;
  }

  return {
    selectedOption,
    selectedDates,
    setSelectedOption,
  };
};
