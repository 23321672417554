import { defineStore } from 'pinia';
import { FilterOptionType } from '@/packages/filter/types/filter-option.type';
import { Cache } from '~/shared/helpers/cache';
import { FiltersRequestDto } from '@/packages/filter/types/filters-request-dto.type';
import { FilteredUsersType } from '@/packages/filter/types/filtered-users.type';
import { convertFilterOptionsToObject } from '@/packages/filter/helpers/convert-filter-options-to-object';

export const useFilterStore = defineStore('filter', {
  state: () => ({
    filtersCache: new Cache<Record<string, FilterOptionType>>(),
    filteredUsersCache: new Cache<FilteredUsersType>(),
  }),

  getters: {
    getFiltersCacheKey(state) {
      return (dto: FiltersRequestDto) => Cache.generateKey(dto);
    },
  },

  actions: {
    cacheFilters(payload: { key: string; filters: FilterOptionType[] }) {
      const object = convertFilterOptionsToObject(payload.filters);
      this.filtersCache.setItem(payload.key, object);
    },

    cacheFilteredUsers(payload: { key: string; users: FilteredUsersType }) {
      this.filteredUsersCache.setItem(payload.key, payload.users);
    },
  },
});
