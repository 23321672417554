import { FilterKeyEnum } from '~/packages/filter/enums/filter-key.enum';
import { ConclusionType } from '~/packages/attrition/types/conclusion.type';
import { RetainedTopPerformersDailyType } from '~/packages/attrition/types/retained-top-performers-daily.type';
import { ReasonsKeyEnum } from '~/packages/attrition/enums/reasons-key.enum';
import { ResultsSavingType } from '~/packages/attrition/types/results-saving.type';
import { ResultsComparisonType } from '~/packages/attrition/types/results-comparison.type';

const retained = [2, 1, 2, 0, 1, 0, 0, 0, 2, 1, 1, 2];
const totalRetained = retained.reduce((total, num) => total + num, 0);
export class ConclusionFakeData {
  public static getConclusion(): Promise<ConclusionType> {
    return Promise.resolve({
      savingTotal: 187500,
      savingMoM: 62500,
      projectedResults: 62500,
      topPerformersRetained: 5,
      teams: ['Gavin Belson’s team', 'Jack Barker’s team'],
      managersFillOutRate: {
        total: 2,
        completed: 2,
      },
      employeesFillOutRate: {
        total: 4,
        completed: 3,
      },
      topDrivers: {
        Promotion: 100,
        Leadership: 80,
        'Work-life balance': 20,
      },
      topReasons: {
        [ReasonsKeyEnum.LACK_OF_PROGRESSION]: 100,
        [ReasonsKeyEnum.MANAGER]: 80,
        [ReasonsKeyEnum.BURNOUT]: 20,
      },
    });
  }

  public static getMoney(): Promise<ResultsSavingType> {
    const points = [...Array(12)].map((_, index) => {
      const month = (index + 1 + '').padStart(2, '0');
      const date = `2022-${month}-15T00:00:00.000Z`;

      return [date, { date, retained: retained[index] }];
    });

    return Promise.resolve({
      savingTotal: 2102500,
      savingMoM: 210250,
      topPerformersRetained: totalRetained,
      graphPoints: Object.fromEntries(points),
    });
  }

  public static getComparison(): Promise<ResultsComparisonType> {
    return Promise.resolve({
      [FilterKeyEnum.TEAM_MANAGER]: [
        {
          id: 123,
          name: 'Nate Spillson',
          users: 8,
        },
        {
          id: 341,
          name: 'Usha Kiran Repakula',
          users: 4,
        },
        {
          id: 491,
          name: 'Elizabeth Jensen',
          users: 5,
        },
        {
          id: 1099,
          name: 'Tameka Williams',
          users: 4,
        },
        {
          id: 1259,
          name: 'Donald Formanek',
          users: 4,
        },
      ],
      [FilterKeyEnum.DIVISION]: [
        {
          id: 5,
          name: 'Product Experience Practice',
          users: 13,
        },
        {
          id: 6,
          name: 'Engineering Practice',
          users: 12,
        },
      ],
      [FilterKeyEnum.DEPARTMENT]: [
        {
          id: 14,
          name: '300-Consultants',
          users: 25,
        },
      ],
      [FilterKeyEnum.POSITION]: [
        {
          id: 59,
          name: 'Consulting Director',
          users: 1,
        },
        {
          id: 64,
          name: 'Delivery Director',
          users: 1,
        },
        {
          id: 75,
          name: 'Group Creative Director',
          users: 1,
        },
        {
          id: 96,
          name: 'Product Manager',
          users: 4,
        },
        {
          id: 97,
          name: 'Product Owner',
          users: 1,
        },
        {
          id: 101,
          name: 'Quality Engineer II',
          users: 1,
        },
        {
          id: 107,
          name: 'Sr. Consulting Director',
          users: 1,
        },
        {
          id: 110,
          name: 'Sr. Delivery Director',
          users: 6,
        },
        {
          id: 121,
          name: 'Sr. Product Director',
          users: 1,
        },
        {
          id: 122,
          name: 'Sr. Product Owner',
          users: 3,
        },
        {
          id: 125,
          name: 'Sr. Quality Engineer I',
          users: 2,
        },
        {
          id: 126,
          name: 'Sr. Quality Engineer II',
          users: 1,
        },
        {
          id: 142,
          name: 'Technology Principal',
          users: 1,
        },
        {
          id: 40423,
          name: 'Director of Agile Excellence',
          users: 1,
        },
      ],
      [FilterKeyEnum.COUNTRY]: [
        {
          id: 1,
          name: 'USA',
          users: 9,
        },
        {
          id: 2,
          name: 'Remote',
          users: 5,
        },
        {
          id: 'NOT_SET',
          name: 'Not set',
          users: 11,
        },
      ],
      [FilterKeyEnum.CITY]: [
        {
          id: 1,
          name: 'Ann Arbor',
          users: 9,
        },
        {
          id: 4,
          name: 'Remote',
          users: 5,
        },
        {
          id: 'NOT_SET',
          name: 'Not set',
          users: 11,
        },
      ],
      [FilterKeyEnum.GENERATION]: [
        {
          id: 2,
          name: 'Generation Y or Millennials (1981-1996)',
          users: 11,
        },
        {
          id: 3,
          name: 'Generation X or Xennials (1965-1980)',
          users: 13,
        },
        {
          id: 4,
          name: 'Baby boomers (1946-1964)',
          users: 1,
        },
      ],
      [FilterKeyEnum.GENDER]: [
        {
          id: 'male',
          name: 'Male',
          users: 14,
        },
        {
          id: 'female',
          name: 'Female',
          users: 11,
        },
      ],
      [FilterKeyEnum.DEI]: [
        {
          id: 'dei 1',
          name: 'dei 1',
          users: 14,
        },
        {
          id: 'dei 2',
          name: 'dei 2',
          users: 11,
        },
      ],
    });
  }

  public static getConclusionWithNoData(): Promise<ConclusionType> {
    return Promise.resolve({
      savingTotal: 0,
      savingMoM: 0,
      projectedResults: 0,
      topPerformersRetained: 0,
      teams: [],
      managersFillOutRate: {
        total: 0,
        completed: 0,
      },
      employeesFillOutRate: {
        total: 0,
        completed: 0,
      },
      topDrivers: {},
      topReasons: {},
    });
  }

  public static getRetainedDaily(): Promise<RetainedTopPerformersDailyType[]> {
    return Promise.resolve([
      { date: '2022-02-15', retained: 4 },
      { date: '2022-03-15', retained: 2 },
      { date: '2022-04-15', retained: 6 },
    ]);
  }
}
