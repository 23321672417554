import { RestApi } from './api';

let core = null as null | RestApi;
let bff = null as null | RestApi;
let pdf = null as null | RestApi;

export const useApi = () => {
  const config = useRuntimeConfig();

  if (!core) {
    core = new RestApi({
      baseUrl: config.public.coreApiUrl,
      // retry: true,
    });
  }

  if (!pdf) {
    pdf = new RestApi({
      baseUrl: config.public.pdfApiUrl,
    });
  }

  if (!bff) {
    const { protocol, host } = window.location;

    bff = new RestApi({
      baseUrl: `${protocol}//${host}/api`,
    });
  }

  return { core, bff, pdf };
};
