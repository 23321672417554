import { AttritionFacade } from '@/packages/attrition/attrition.facade';

export default defineNuxtPlugin(() => {
  const attrition = new AttritionFacade();

  return {
    provide: {
      attrition,
    },
  };
});
