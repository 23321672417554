import { SlackFacade } from './slack.facade';

export default defineNuxtPlugin(() => {
  const facade = new SlackFacade();

  return {
    provide: {
      slack: facade,
    },
  };
});
