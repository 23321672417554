import { Faker } from '@/packages/fake-data/faker';
import { AppService } from '~/app/app.service';

export class SurveyService extends AppService {
  sendSurveyReply(id: number | string, form: any): Promise<void> {
    if (Faker.isEnabled()) {
      return Promise.resolve();
    }

    return this.api.core.post(`/survey/reply`, {
      surveyId: id,
      answers: form,
    });
  }
}
